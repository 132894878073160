// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: go.chromium.org/luci/resultdb/proto/v1/resultdb.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { FieldMask } from "../../../../../google/protobuf/field_mask.pb";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { Artifact, ArtifactLine } from "./artifact.pb";
import { Variant } from "./common.pb";
import { Instruction, InstructionTarget, instructionTargetFromJSON, instructionTargetToJSON } from "./instruction.pb";
import { Invocation, Sources } from "./invocation.pb";
import {
  ArtifactPredicate,
  TestExonerationPredicate,
  TestMetadataPredicate,
  TestResultPredicate,
} from "./predicate.pb";
import { TestMetadataDetail } from "./test_metadata.pb";
import { TestExoneration, TestResult, TestStatus, testStatusFromJSON, testStatusToJSON } from "./test_result.pb";
import { RunTestVerdict, TestVariant, TestVariantPredicate } from "./test_variant.pb";

export const protobufPackage = "luci.resultdb.v1";

/** A request message for GetInvocation RPC. */
export interface GetInvocationRequest {
  /** The name of the invocation to request, see Invocation.name. */
  readonly name: string;
}

/** A request message for QueryRootInvocationNames RPC. */
export interface QueryRootInvocationNamesRequest {
  /** The name of the invocation to request, see Invocation.name. */
  readonly name: string;
}

/** A response message for QueryRootInvocationNames RPC. */
export interface QueryRootInvocationNamesResponse {
  /**
   * The name of all root invocations to the invocation in the request.
   * A root either
   *   - has is_export_root = true, or
   *   - has no parent invocation.
   *
   * If the invocation to request is already a root,
   * the root_invocation_names will only contains the invocation in the request
   * (i.e. a invocation can be its own root).
   *
   * root_invocation_names can be empty when no root is found (in the presence of cycles).
   */
  readonly rootInvocationNames: readonly string[];
}

/** A request message for GetTestResult RPC. */
export interface GetTestResultRequest {
  /** The name of the test result to request, see TestResult.name. */
  readonly name: string;
}

/** A request message for ListTestResults RPC. */
export interface ListTestResultsRequest {
  /** Name of the invocation, e.g. "invocations/{id}". */
  readonly invocation: string;
  /**
   * The maximum number of test results to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 test results will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListTestResults` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListTestResults` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
  /**
   * Fields to include in the response.
   * If not set, the default mask is used where summary_html and tags are
   * excluded.
   * Test result names will always be included even if "name" is not a part of
   * the mask.
   */
  readonly readMask: readonly string[] | undefined;
}

/** A response message for ListTestResults RPC. */
export interface ListTestResultsResponse {
  /** The test results from the specified invocation. */
  readonly testResults: readonly TestResult[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   * If the invocation is not finalized, more results may appear later.
   */
  readonly nextPageToken: string;
}

/** A request message for GetTestExoneration RPC. */
export interface GetTestExonerationRequest {
  /** The name of the test exoneration to request, see TestExoneration.name. */
  readonly name: string;
}

/** A request message for ListTestExonerations RPC. */
export interface ListTestExonerationsRequest {
  /** Name of the invocation, e.g. "invocations/{id}". */
  readonly invocation: string;
  /**
   * The maximum number of test exonerations to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 test exonerations will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListTestExonerations` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListTestExonerations`
   * MUST match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response message for ListTestExonerations RPC. */
export interface ListTestExonerationsResponse {
  /** The test exonerations from the specified invocation. */
  readonly testExonerations: readonly TestExoneration[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   * If the invocation is not finalized, more results may appear later.
   */
  readonly nextPageToken: string;
}

/** A request message for QueryTestResults RPC. */
export interface QueryTestResultsRequest {
  /**
   * Retrieve test results included in these invocations, directly or indirectly
   * (via Invocation.included_invocations).
   *
   * Specifying multiple invocations is equivalent to querying one invocation
   * that includes these.
   */
  readonly invocations: readonly string[];
  /** A test result in the response must satisfy this predicate. */
  readonly predicate:
    | TestResultPredicate
    | undefined;
  /**
   * The maximum number of test results to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 test results will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestResults` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestResults` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
  /**
   * Fields to include in the response.
   * If not set, the default mask is used where summary_html and tags are
   * excluded.
   * Test result names will always be included even if "name" is not a part of
   * the mask.
   */
  readonly readMask: readonly string[] | undefined;
}

/** A response message for QueryTestResults RPC. */
export interface QueryTestResultsResponse {
  /**
   * Matched test results.
   * Ordered by parent invocation ID, test ID and result ID.
   */
  readonly testResults: readonly TestResult[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/** A request message for QueryTestExonerations RPC. */
export interface QueryTestExonerationsRequest {
  /**
   * Retrieve test exonerations included in these invocations, directly or
   * indirectly (via Invocation.included_invocations).
   *
   * Specifying multiple invocations is equivalent to querying one invocation
   * that includes these.
   */
  readonly invocations: readonly string[];
  /** A test exoneration in the response must satisfy this predicate. */
  readonly predicate:
    | TestExonerationPredicate
    | undefined;
  /**
   * The maximum number of test exonerations to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 test exonerations will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestExonerations` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestExonerations`
   * MUST match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response message for QueryTestExonerations RPC. */
export interface QueryTestExonerationsResponse {
  /**
   * The test exonerations matching the predicate.
   * Ordered by parent invocation ID, test ID and exoneration ID.
   */
  readonly testExonerations: readonly TestExoneration[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/** A request message for QueryTestResultStatistics RPC. */
export interface QueryTestResultStatisticsRequest {
  /**
   * Retrieve statistics of test result belong to these invocations,
   * directly or indirectly (via Invocation.included_invocations).
   *
   * Specifying multiple invocations is equivalent to requesting one invocation
   * that includes these.
   */
  readonly invocations: readonly string[];
}

/** A response message for QueryTestResultStatistics RPC. */
export interface QueryTestResultStatisticsResponse {
  /** Total number of test results. */
  readonly totalTestResults: string;
}

/** A request message for GetArtifact RPC. */
export interface GetArtifactRequest {
  /** The name of the artifact to request, see Artifact.name. */
  readonly name: string;
}

/** A request message for ListArtifacts RPC. */
export interface ListArtifactsRequest {
  /**
   * Name of the parent, e.g. an invocation (see Invocation.name) or
   * a test result (see TestResult.name).
   */
  readonly parent: string;
  /**
   * The maximum number of artifacts to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 artifacts will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListArtifacts` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListArtifacts` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response message for ListArtifacts RPC. */
export interface ListArtifactsResponse {
  /** The artifacts from the specified parent. */
  readonly artifacts: readonly Artifact[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   * If the invocation is not finalized, more results may appear later.
   */
  readonly nextPageToken: string;
}

/** A request message for QueryArtifacts RPC. */
export interface QueryArtifactsRequest {
  /**
   * Retrieve artifacts included in these invocations, directly or indirectly
   * (via Invocation.included_invocations and via contained test results).
   *
   * Specifying multiple invocations is equivalent to querying one invocation
   * that includes these.
   */
  readonly invocations: readonly string[];
  /** An artifact in the response must satisfy this predicate. */
  readonly predicate:
    | ArtifactPredicate
    | undefined;
  /**
   * The maximum number of artifacts to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 artifacts will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryArtifacts` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryArtifacts` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response message for QueryArtifacts RPC. */
export interface QueryArtifactsResponse {
  /**
   * Matched artifacts.
   * First invocation-level artifacts, then test-result-level artifacts
   * ordered by parent invocation ID, test ID and artifact ID.
   */
  readonly artifacts: readonly Artifact[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/** A request message for ListArtifactLines RPC. */
export interface ListArtifactLinesRequest {
  /**
   * Retrieve log lines for this artifact.
   * Format:
   * - For invocation-level artifacts:
   *   "invocations/{INVOCATION_ID}/artifacts/{ARTIFACT_ID}".
   * - For test-result-level artifacts:
   *   "invocations/{INVOCATION_ID}/tests/{URL_ESCAPED_TEST_ID}/results/{RESULT_ID}/artifacts/{ARTIFACT_ID}".
   */
  readonly parent: string;
  /**
   * The maximum number of log lines to return.
   *
   * The service may return fewer than this value.
   * The response size will be truncated to 10MB
   * if the size of matching lines is larger than 10MB.
   * If unspecified or is equal to 0, a max of 10MB of lines will be returned.
   * Negative page sizes are not allowed.
   */
  readonly pageSize: number;
}

/** A response for ListArtifactLines RPC */
export interface ListArtifactLinesResponse {
  /** The log lines in the artifact. */
  readonly lines: readonly ArtifactLine[];
}

/** A request for the QueryArtifactFailureOnlyLines RPC. */
export interface QueryArtifactFailureOnlyLinesRequest {
  /**
   * Retrieve log lines for this artifact.
   * Invocation level artifacts are not yet supported.
   * Format:
   * - For test-result-level artifacts:
   *   "invocations/{INVOCATION_ID}/tests/{URL_ESCAPED_TEST_ID}/results/{RESULT_ID}/artifacts/{ARTIFACT_ID}".
   */
  readonly parent: string;
  /**
   * If set to true, the content of the log lines will be returned in the
   * response.  If left missing or false, only the line range indexes will
   * be returned.
   */
  readonly includeContent: boolean;
  /**
   * The maximum number of line ranges to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 1000 line ranges will be returned.
   * The maximum value is 10,000; values above 10,000 will be coerced to 10,000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryArtifactFailureOnlyLines` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryArtifactFailureOnlyLines` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response for the QueryArtifactFailureOnlyLines RPC. */
export interface QueryArtifactFailureOnlyLinesResponse {
  /**
   * Line ranges [start, end) in the requested artifact that do not typically
   * appear versions of the artifact from passing test results.
   * Line ranges are returned in sorted ascending order.
   */
  readonly failureOnlyLineRanges: readonly QueryArtifactFailureOnlyLinesResponse_LineRange[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/**
 * A representation of a range of lines in an artifact, where lines are
 * represented by their index.  The first line is line 0.
 */
export interface QueryArtifactFailureOnlyLinesResponse_LineRange {
  /**
   * The line index of the start of the line range.  The start is inclusive,
   * i.e. the start line is included in the range.
   */
  readonly start: number;
  /**
   * The line index of the end of the line range.  The end is exclusive, i.e.
   * the end line is not included in the range.
   */
  readonly end: number;
  /**
   * The content of the lines in the range.
   * Only included if include_content in the request is set to true.
   */
  readonly lines: readonly string[];
}

/**
 * A request message for QueryTestVariants RPC.
 * Next id: 9.
 */
export interface QueryTestVariantsRequest {
  /**
   * Retrieve test verdicts included in these invocations, directly or indirectly
   * (via Invocation.included_invocations).
   *
   * As of April 2024, a maximum of one invocation may be specified.
   */
  readonly invocations: readonly string[];
  /** A test variant must satisfy this predicate. */
  readonly predicate:
    | TestVariantPredicate
    | undefined;
  /**
   * The maximum number of test results to be included in a test variant.
   *
   * If a test variant has more results than the limit, the remaining results
   * will not be returned.
   * If unspecified, at most 10 results will be included in a test variant.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly resultLimit: number;
  /**
   * The maximum number of test variants to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 100 test variants will be returned.
   * The maximum value is 10,000; values above 10,000 will be coerced to 10,000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestVariants` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestVariants` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
  /**
   * Fields to include in the response.
   * If not set, the default mask is used where all fields are included.
   *
   * The following fields in results.*.result will NEVER be included even when
   * specified:
   * * test_id
   * * variant_hash
   * * variant
   * * test_metadata
   * Those values can be found in the parent test variant objects.
   *
   * The following fields will ALWAYS be included even when NOT specified:
   * * test_id
   * * variant_hash
   * * status
   */
  readonly readMask: readonly string[] | undefined;
}

/** A response message for QueryTestVariants RPC. */
export interface QueryTestVariantsResponse {
  /**
   * Matched test variants.
   * Ordered by TestVariantStatus, test_id, then variant_hash
   */
  readonly testVariants: readonly TestVariant[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
  /**
   * The code sources tested by the returned test variants. The sources are keyed
   * by an ID which allows them to be cross-referenced from TestVariant.sources_id.
   *
   * The sources are returned via this map instead of directly on the TestVariant
   * to avoid excessive response size. Each source message could be up to a few
   * kilobytes and there are usually no more than a handful of different sources
   * tested in an invocation, so deduplicating them here reduces response size.
   */
  readonly sources: { [key: string]: Sources };
}

export interface QueryTestVariantsResponse_SourcesEntry {
  readonly key: string;
  readonly value: Sources | undefined;
}

/** Request message for QueryRunTestVerdicts RPC. */
export interface QueryRunTestVerdictsRequest {
  /**
   * Retrieve test verdicts for the test run represented by this invocation.
   * Format: invocations/{INVOCATION_ID}.
   */
  readonly invocation: string;
  /**
   * The maximum number of test results to be included in a test variant.
   *
   * If a test variant has more results than the limit, the remaining results
   * will not be returned.
   * If unspecified, at most 10 results will be included in a test variant.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly resultLimit: number;
  /**
   * The maximum number of test variants to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 1000 test variants will be returned.
   * The maximum value is 10,000; values above 10,000 will be coerced to 10,000.
   * Page sizes smaller than the maximum may be returned at the server's
   * discretion, for example, due to keep the response size tractable for
   * the server.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryRunTestVerdicts` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to
   * `QueryRunTestVerdicts` MUST match the call that provided the page
   * token.
   */
  readonly pageToken: string;
}

/** A response message for QueryRunTestVerdicts RPC. */
export interface QueryRunTestVerdictsResponse {
  /**
   * Test verdicts for the run.
   *
   * Ordered by test_id, then variant_hash.
   */
  readonly runTestVerdicts: readonly RunTestVerdict[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/** A request message for BatchGetTestVariants RPC. */
export interface BatchGetTestVariantsRequest {
  /** Name of the invocation that the test variants are in. */
  readonly invocation: string;
  /**
   * A list of test IDs and variant hashes, identifying the requested test
   * variants. Size is limited to 500. Any request for more than 500 variants
   * will return an error.
   */
  readonly testVariants: readonly BatchGetTestVariantsRequest_TestVariantIdentifier[];
  /**
   * The maximum number of test results to be included in a test variant.
   *
   * If a test variant has more results than the limit, the remaining results
   * will not be returned.
   * If unspecified, at most 10 results will be included in a test variant.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly resultLimit: number;
}

export interface BatchGetTestVariantsRequest_TestVariantIdentifier {
  /**
   * The unique identifier of the test in a LUCI project. See the comment on
   * TestResult.test_id for full documentation.
   */
  readonly testId: string;
  /**
   * Hash of the variant. See the comment on TestResult.variant_hash for full
   * documentation.
   */
  readonly variantHash: string;
}

/** A response message for BatchGetTestVariants RPC. */
export interface BatchGetTestVariantsResponse {
  /**
   * Test variants matching the requests. Any variants that weren't found are
   * omitted from the response. Clients shouldn't rely on the ordering of this
   * field, as no particular order is guaranteed.
   */
  readonly testVariants: readonly TestVariant[];
  /**
   * The code sources tested by the returned test variants. The sources are keyed
   * by an ID which allows them to be cross-referenced from TestVariant.sources_id.
   *
   * The sources are returned via this map instead of directly on the TestVariant
   * to avoid excessive response size. Each source message could be up to a few
   * kilobytes and there are usually no more than a handful of different sources
   * tested in an invocation, so deduplicating them here reduces response size.
   */
  readonly sources: { [key: string]: Sources };
}

export interface BatchGetTestVariantsResponse_SourcesEntry {
  readonly key: string;
  readonly value: Sources | undefined;
}

/** A request message for QueryTestMetadata RPC. */
export interface QueryTestMetadataRequest {
  /** The LUCI Project to query. */
  readonly project: string;
  /** Filters to apply to the returned test metadata. */
  readonly predicate:
    | TestMetadataPredicate
    | undefined;
  /**
   * The maximum number of test metadata entries to return.
   *
   * The service may return fewer than this value.
   * If unspecified, at most 1000 test metadata entries will be returned.
   * The maximum value is 100K; values above 100K will be coerced to 100K.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestMetadata` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestMetadata` MUST
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

/** A response message for QueryTestMetadata RPC. */
export interface QueryTestMetadataResponse {
  /** The matched testMetadata. */
  readonly testMetadata: readonly TestMetadataDetail[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there were no subsequent pages at the time of
   * request.
   */
  readonly nextPageToken: string;
}

/**
 * A request message for QueryNewTestVariants RPC.
 * To use this RPC, callers need:
 * - resultdb.baselines.get in the realm the <baseline_project>:@project, where
 *   baseline_project is the LUCI project that contains the baseline.
 * - resultdb.testResults.list in the realm of the invocation which is being
 *   queried.
 */
export interface QueryNewTestVariantsRequest {
  /** Name of the invocation, e.g. "invocations/{id}". */
  readonly invocation: string;
  /**
   * The baseline to compare test variants against, to determine if they are new.
   * e.g. “projects/{project}/baselines/{baseline_id}”.
   * For example, in the project "chromium", the baseline_id may be
   * "try:linux-rel".
   */
  readonly baseline: string;
}

/** A response message for QueryNewTestVariants RPC. */
export interface QueryNewTestVariantsResponse {
  /**
   * Indicates whether the baseline has been populated with at least 72 hours
   * of data and the results can be relied upon.
   */
  readonly isBaselineReady: boolean;
  /**
   * Test variants that are new, meaning that they have not been part of
   * a submitted run prior.
   */
  readonly newTestVariants: readonly QueryNewTestVariantsResponse_NewTestVariant[];
}

/** Represents a new test, which contains minimal information to uniquely identify a TestVariant. */
export interface QueryNewTestVariantsResponse_NewTestVariant {
  /**
   * A unique identifier of the test in a LUCI project.
   * Regex: ^[[::print::]]{1,256}$
   *
   * Refer to TestResult.test_id for details.
   */
  readonly testId: string;
  /**
   * Hash of the variant.
   * hex(sha256(sorted(''.join('%s:%s\n' for k, v in variant.items())))).
   */
  readonly variantHash: string;
}

export interface GetInstructionRequest {
  /**
   * Name of the instruction. The format is:
   * invocations/{invocation_id}/instructions/{instruction_id}
   */
  readonly name: string;
}

/** Request for QueryInstructionDependencies RPC. */
export interface QueryInstructionRequest {
  /**
   * Name of the instruction to query for. The format is:
   * invocations/{invocation_id}/instructions/{instruction_id}
   */
  readonly name: string;
  /**
   * The maximum depth to traverse the dependency chain. Default is 5.
   * The maximum value we support is 10, value bigger than 10 will be adjusted to 10.
   * Non-positive value will be adjusted to the default.
   */
  readonly dependencyMaxDepth: number;
}

/** Response for QueryInstructionDependencies RPC. */
export interface QueryInstructionResponse {
  /** The instruction. */
  readonly instruction:
    | Instruction
    | undefined;
  /** Dependency chain, one for each target. */
  readonly dependencyChains: readonly InstructionDependencyChain[];
}

/** The dependency chain for one target. */
export interface InstructionDependencyChain {
  /** Target of the dependency chain. */
  readonly target: InstructionTarget;
  /**
   * List of dependencies.
   * The list will be sorted by the position in the dependency chain.
   * The direct dependency will be at position 0.
   * If the dependency traversing encounters an error, the last node will contain the error.
   */
  readonly nodes: readonly InstructionDependencyChain_Node[];
}

/** Captures information about a dependency. */
export interface InstructionDependencyChain_Node {
  /**
   * The instruction name that the dependency belongs to.
   * The format is invocations/{invocation_id}/instructions/{instruction_id}.
   * We need this for the UI to resolve the placeholders of the content.
   */
  readonly instructionName: string;
  /**
   * Content of the dependency.
   * Placeholders (if existed) will be returned as-is.
   * The caller of this RPC is responsible for resolving the placeholders.
   */
  readonly content: string;
  /**
   * In case the traversal encounters an error, the error will be returned in this field.
   * If an error is returned, it will only be returned in the last dependency node,
   * after that, the chain will stop.
   */
  readonly error: string;
  /**
   * The descriptive name of the instruction that the dependency belongs to.
   * In the following cases, the descriptive name will not be set:
   * - If the user does not have the permission to access the instruction for the node, or
   * - If the instruction cannot be found.
   */
  readonly descriptiveName: string;
}

export interface QueryTestVariantArtifactGroupsRequest {
  /** The LUCI project (required). */
  readonly project: string;
  /**
   * The search string to search in text artifact content (required).
   * Support regex or exact match.
   */
  readonly searchString:
    | ArtifactContentMatcher
    | undefined;
  /** The test id matcher to restrict the scope of the search (optional). */
  readonly testIdMatcher:
    | IDMatcher
    | undefined;
  /** The artifact id matcher to restrict the scope of the search (optional). */
  readonly artifactIdMatcher:
    | IDMatcher
    | undefined;
  /**
   * The lower bound of the time range to search in UTC time (exclusive) (required).
   * start_time must not be before 20 July 2024 UTC.
   * start_time must be less than the end time.
   * The duration between start_time and end_time must not be greater than 7 days.
   */
  readonly startTime:
    | string
    | undefined;
  /** The upper bound of the time range to search in UTC time (inclusive) (required). */
  readonly endTime:
    | string
    | undefined;
  /**
   * The maximum number of match groups to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 items will be returned.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestVariantArtifactGroups` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestVariantArtifactGroups` must
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QueryTestVariantArtifactGroupsResponse {
  /**
   * Test variant artifacts are grouped by test_id, variant_hash and artifact_id.
   * Groups are ordered by partition_time of the most recent artifact DESC in the group, test id, variant hash, artifact id.
   */
  readonly groups: readonly QueryTestVariantArtifactGroupsResponse_MatchGroup[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

/**
 * Represents one group of matched artifacts.
 * test_id, variant_hash and artifact_id form the group key.
 */
export interface QueryTestVariantArtifactGroupsResponse_MatchGroup {
  /** The LUCI test id. */
  readonly testId: string;
  /** The hash of the variant. */
  readonly variantHash: string;
  /**
   * The definition of the variant.
   * Description of one specific way of running the test,
   * e.g. a specific bucket, builder and a test suite.
   */
  readonly variant:
    | Variant
    | undefined;
  /** ID of the artifact. */
  readonly artifactId: string;
  /**
   * Artifacts that matches the search for this (test id, variant_hash, artifact id) combination.
   * Return at most 3 items, ordered by partition time DESC, artifact name ASC.
   */
  readonly artifacts: readonly ArtifactMatchingContent[];
  /** The total number of matching artifacts for this (test id, variant_hash, artifact id) combination. */
  readonly matchingCount: number;
}

export interface QueryTestVariantArtifactsRequest {
  /** The LUCI project (required). */
  readonly project: string;
  /**
   * The search string to search in text artifact content (required).
   * Support regex or exact match.
   */
  readonly searchString:
    | ArtifactContentMatcher
    | undefined;
  /** The test id. (required). */
  readonly testId: string;
  /** The variant hash (required). */
  readonly variantHash: string;
  /** The artifact id (required). */
  readonly artifactId: string;
  /**
   * The lower bound of the time range to search in UTC time (exclusive) (required).
   * start_time must not be before 20 July 2024 UTC.
   * start_time must be less than the end time.
   * The duration between start_time and end_time must not be greater than 7 days.
   */
  readonly startTime:
    | string
    | undefined;
  /** The upper bound of the time range to search in UTC time (inclusive) (required). */
  readonly endTime:
    | string
    | undefined;
  /**
   * The maximum number of items to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 items will be returned.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryTestVariantArtifacts` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryTestVariantArtifacts` must
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QueryTestVariantArtifactsResponse {
  /**
   * Artifacts that matches the search.
   * Ordered by partition_time DESC, artifact name.
   */
  readonly artifacts: readonly ArtifactMatchingContent[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface QueryInvocationVariantArtifactGroupsRequest {
  /** The LUCI project (required). */
  readonly project: string;
  /**
   * The search string to search in text artifact content (required).
   * Support regex or exact match.
   */
  readonly searchString:
    | ArtifactContentMatcher
    | undefined;
  /** The artifact id matcher to restrict the scope of the search (optional). */
  readonly artifactIdMatcher:
    | IDMatcher
    | undefined;
  /**
   * The lower bound of the time range to search in UTC time (exclusive) (required).
   * start_time must not be before 20 July 2024 UTC.
   * start_time must be less than the end time.
   * The duration between start_time and end_time must not be greater than 7 days.
   */
  readonly startTime:
    | string
    | undefined;
  /** The upper bound of the time range to search in UTC time (inclusive) (required). */
  readonly endTime:
    | string
    | undefined;
  /**
   * The maximum number of match groups to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 items will be returned.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryInvocationVariantArtifactGroups` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryInvocationVariantArtifactGroups` must
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QueryInvocationVariantArtifactGroupsResponse {
  /**
   * Invocation variant artifacts  are grouped by variant_union_hash and artifact_id.
   * Ordered by partition_time of the most recent artifact DESC in the group, variant_union_hash, artifact id.
   */
  readonly groups: readonly QueryInvocationVariantArtifactGroupsResponse_MatchGroup[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

/**
 * Represents one group of matched artifacts.
 * variant_union_hash and artifact_id form the group key.
 */
export interface QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
  /** Hash of the union of all variants of test results directly included by the invocation. */
  readonly variantUnionHash: string;
  /**
   * Union of all variants of test results directly included by the invocation.
   * Roughly defines a specific way to run an invocation.
   */
  readonly variantUnion:
    | Variant
    | undefined;
  /** ID of the artifact. */
  readonly artifactId: string;
  /**
   * Artifacts that matches the search for this (variant_union_hash, artifact id) combination.
   * Return at most 3 items, ordered by partition time DESC, artifact name ASC.
   */
  readonly artifacts: readonly ArtifactMatchingContent[];
  /** The total number of matching artifacts for this (variant_union_hash, artifact id) combination. */
  readonly matchingCount: number;
}

export interface QueryInvocationVariantArtifactsRequest {
  /** The LUCI project (required). */
  readonly project: string;
  /**
   * The search string to search in text artifact content (required).
   * Support regex or exact match.
   */
  readonly searchString:
    | ArtifactContentMatcher
    | undefined;
  /** Hash of the union of all variants of test results directly included by the invocation (required). */
  readonly variantUnionHash: string;
  /** The artifact id (required). */
  readonly artifactId: string;
  /**
   * The lower bound of the time range to search in UTC time (exclusive) (required).
   * start_time must not be before 20 July 2024 UTC.
   * start_time must be less than the end time.
   * The duration between start_time and end_time must not be greater than 7 days.
   */
  readonly startTime:
    | string
    | undefined;
  /** The upper bound of the time range to search in UTC time (inclusive) (required). */
  readonly endTime:
    | string
    | undefined;
  /**
   * The maximum number of items to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 items will be returned.
   * The maximum value is 100; values above 100 will be coerced to 100.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `QueryInvocationVariantArtifacts` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `QueryInvocationVariantArtifacts` must
   * match the call that provided the page token.
   */
  readonly pageToken: string;
}

export interface QueryInvocationVariantArtifactsResponse {
  /**
   * Artifacts that matches the search.
   * Ordered by partition_time DESC, artifact name.
   */
  readonly artifacts: readonly ArtifactMatchingContent[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

/** Represent a artifact that contains a match. */
export interface ArtifactMatchingContent {
  /**
   * Name of the artifact.
   * - For invocation-level artifacts:
   *   "invocations/{INVOCATION_ID}/artifacts/{ARTIFACT_ID}".
   * - For test-result-level artifacts:
   *   "invocations/{INVOCATION_ID}/tests/{URL_ESCAPED_TEST_ID}/results/{RESULT_ID}/artifacts/{ARTIFACT_ID}".
   */
  readonly name: string;
  /**
   * Partition time of the artifact.
   * This is the creation time of the direct parent invocation.
   */
  readonly partitionTime:
    | string
    | undefined;
  /** The test result status, only populated if it is a result level artifact . */
  readonly testStatus: TestStatus;
  /**
   * Part of the artifact content that contains the first occurrence of the match.
   * The snippet is at most 10 KiB.
   * Prioritize fiting first match into the 10KiB first, divided the remaining bytes equally
   * to fit content immediately before and after the first match, including at most one more line above and below.
   * If the first match is more than 10KiB, it will be truncated.
   * Ellipsis ("...") are added, if the snippet is truncated.
   */
  readonly snippet: string;
  /** All non-overlapping matches exists in the snippet from front to end in order (i.e. matches[i].end_index <= matches[i+1].start_index). */
  readonly matches: readonly ArtifactMatchingContent_Match[];
}

/** Represent the byte location of a match in snippet. */
export interface ArtifactMatchingContent_Match {
  /** Start byte index of the match, inclusive. */
  readonly startIndex: number;
  /** End byte index of the match, exclusive. */
  readonly endIndex: number;
}

/** Used to match a artifact content. */
export interface ArtifactContentMatcher {
  /** The string is a regex expression. Use regex match to find matching artifact content. */
  readonly regexContain?:
    | string
    | undefined;
  /** Use case insensitive equality match with this string to find matching artifact content. */
  readonly contain?: string | undefined;
}

/** Used to match IDs (eg. test id, artifact id). */
export interface IDMatcher {
  /** The id should has a matching prefix. */
  readonly hasPrefix?:
    | string
    | undefined;
  /** The id should exactly equal to this string. */
  readonly exactEqual?: string | undefined;
}

function createBaseGetInvocationRequest(): GetInvocationRequest {
  return { name: "" };
}

export const GetInvocationRequest: MessageFns<GetInvocationRequest> = {
  encode(message: GetInvocationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetInvocationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInvocationRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInvocationRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetInvocationRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetInvocationRequest>): GetInvocationRequest {
    return GetInvocationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetInvocationRequest>): GetInvocationRequest {
    const message = createBaseGetInvocationRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseQueryRootInvocationNamesRequest(): QueryRootInvocationNamesRequest {
  return { name: "" };
}

export const QueryRootInvocationNamesRequest: MessageFns<QueryRootInvocationNamesRequest> = {
  encode(message: QueryRootInvocationNamesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRootInvocationNamesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRootInvocationNamesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRootInvocationNamesRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: QueryRootInvocationNamesRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryRootInvocationNamesRequest>): QueryRootInvocationNamesRequest {
    return QueryRootInvocationNamesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryRootInvocationNamesRequest>): QueryRootInvocationNamesRequest {
    const message = createBaseQueryRootInvocationNamesRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseQueryRootInvocationNamesResponse(): QueryRootInvocationNamesResponse {
  return { rootInvocationNames: [] };
}

export const QueryRootInvocationNamesResponse: MessageFns<QueryRootInvocationNamesResponse> = {
  encode(message: QueryRootInvocationNamesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rootInvocationNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRootInvocationNamesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRootInvocationNamesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rootInvocationNames.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRootInvocationNamesResponse {
    return {
      rootInvocationNames: globalThis.Array.isArray(object?.rootInvocationNames)
        ? object.rootInvocationNames.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: QueryRootInvocationNamesResponse): unknown {
    const obj: any = {};
    if (message.rootInvocationNames?.length) {
      obj.rootInvocationNames = message.rootInvocationNames;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryRootInvocationNamesResponse>): QueryRootInvocationNamesResponse {
    return QueryRootInvocationNamesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryRootInvocationNamesResponse>): QueryRootInvocationNamesResponse {
    const message = createBaseQueryRootInvocationNamesResponse() as any;
    message.rootInvocationNames = object.rootInvocationNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetTestResultRequest(): GetTestResultRequest {
  return { name: "" };
}

export const GetTestResultRequest: MessageFns<GetTestResultRequest> = {
  encode(message: GetTestResultRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTestResultRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTestResultRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTestResultRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetTestResultRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetTestResultRequest>): GetTestResultRequest {
    return GetTestResultRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetTestResultRequest>): GetTestResultRequest {
    const message = createBaseGetTestResultRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListTestResultsRequest(): ListTestResultsRequest {
  return { invocation: "", pageSize: 0, pageToken: "", readMask: undefined };
}

export const ListTestResultsRequest: MessageFns<ListTestResultsRequest> = {
  encode(message: ListTestResultsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocation !== "") {
      writer.uint32(10).string(message.invocation);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(26).string(message.pageToken);
    }
    if (message.readMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.readMask), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTestResultsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTestResultsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocation = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.readMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTestResultsRequest {
    return {
      invocation: isSet(object.invocation) ? globalThis.String(object.invocation) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      readMask: isSet(object.readMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.readMask)) : undefined,
    };
  },

  toJSON(message: ListTestResultsRequest): unknown {
    const obj: any = {};
    if (message.invocation !== "") {
      obj.invocation = message.invocation;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.readMask !== undefined) {
      obj.readMask = FieldMask.toJSON(FieldMask.wrap(message.readMask));
    }
    return obj;
  },

  create(base?: DeepPartial<ListTestResultsRequest>): ListTestResultsRequest {
    return ListTestResultsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTestResultsRequest>): ListTestResultsRequest {
    const message = createBaseListTestResultsRequest() as any;
    message.invocation = object.invocation ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.readMask = object.readMask ?? undefined;
    return message;
  },
};

function createBaseListTestResultsResponse(): ListTestResultsResponse {
  return { testResults: [], nextPageToken: "" };
}

export const ListTestResultsResponse: MessageFns<ListTestResultsResponse> = {
  encode(message: ListTestResultsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testResults) {
      TestResult.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTestResultsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTestResultsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testResults.push(TestResult.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTestResultsResponse {
    return {
      testResults: globalThis.Array.isArray(object?.testResults)
        ? object.testResults.map((e: any) => TestResult.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListTestResultsResponse): unknown {
    const obj: any = {};
    if (message.testResults?.length) {
      obj.testResults = message.testResults.map((e) => TestResult.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListTestResultsResponse>): ListTestResultsResponse {
    return ListTestResultsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTestResultsResponse>): ListTestResultsResponse {
    const message = createBaseListTestResultsResponse() as any;
    message.testResults = object.testResults?.map((e) => TestResult.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetTestExonerationRequest(): GetTestExonerationRequest {
  return { name: "" };
}

export const GetTestExonerationRequest: MessageFns<GetTestExonerationRequest> = {
  encode(message: GetTestExonerationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetTestExonerationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTestExonerationRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTestExonerationRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetTestExonerationRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetTestExonerationRequest>): GetTestExonerationRequest {
    return GetTestExonerationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetTestExonerationRequest>): GetTestExonerationRequest {
    const message = createBaseGetTestExonerationRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListTestExonerationsRequest(): ListTestExonerationsRequest {
  return { invocation: "", pageSize: 0, pageToken: "" };
}

export const ListTestExonerationsRequest: MessageFns<ListTestExonerationsRequest> = {
  encode(message: ListTestExonerationsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocation !== "") {
      writer.uint32(10).string(message.invocation);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(26).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTestExonerationsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTestExonerationsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocation = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTestExonerationsRequest {
    return {
      invocation: isSet(object.invocation) ? globalThis.String(object.invocation) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: ListTestExonerationsRequest): unknown {
    const obj: any = {};
    if (message.invocation !== "") {
      obj.invocation = message.invocation;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListTestExonerationsRequest>): ListTestExonerationsRequest {
    return ListTestExonerationsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTestExonerationsRequest>): ListTestExonerationsRequest {
    const message = createBaseListTestExonerationsRequest() as any;
    message.invocation = object.invocation ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListTestExonerationsResponse(): ListTestExonerationsResponse {
  return { testExonerations: [], nextPageToken: "" };
}

export const ListTestExonerationsResponse: MessageFns<ListTestExonerationsResponse> = {
  encode(message: ListTestExonerationsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testExonerations) {
      TestExoneration.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTestExonerationsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTestExonerationsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testExonerations.push(TestExoneration.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTestExonerationsResponse {
    return {
      testExonerations: globalThis.Array.isArray(object?.testExonerations)
        ? object.testExonerations.map((e: any) => TestExoneration.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListTestExonerationsResponse): unknown {
    const obj: any = {};
    if (message.testExonerations?.length) {
      obj.testExonerations = message.testExonerations.map((e) => TestExoneration.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListTestExonerationsResponse>): ListTestExonerationsResponse {
    return ListTestExonerationsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTestExonerationsResponse>): ListTestExonerationsResponse {
    const message = createBaseListTestExonerationsResponse() as any;
    message.testExonerations = object.testExonerations?.map((e) => TestExoneration.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryTestResultsRequest(): QueryTestResultsRequest {
  return { invocations: [], predicate: undefined, pageSize: 0, pageToken: "", readMask: undefined };
}

export const QueryTestResultsRequest: MessageFns<QueryTestResultsRequest> = {
  encode(message: QueryTestResultsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.invocations) {
      writer.uint32(10).string(v!);
    }
    if (message.predicate !== undefined) {
      TestResultPredicate.encode(message.predicate, writer.uint32(18).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    if (message.readMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.readMask), writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestResultsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestResultsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocations.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.predicate = TestResultPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.readMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestResultsRequest {
    return {
      invocations: globalThis.Array.isArray(object?.invocations)
        ? object.invocations.map((e: any) => globalThis.String(e))
        : [],
      predicate: isSet(object.predicate) ? TestResultPredicate.fromJSON(object.predicate) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      readMask: isSet(object.readMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.readMask)) : undefined,
    };
  },

  toJSON(message: QueryTestResultsRequest): unknown {
    const obj: any = {};
    if (message.invocations?.length) {
      obj.invocations = message.invocations;
    }
    if (message.predicate !== undefined) {
      obj.predicate = TestResultPredicate.toJSON(message.predicate);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.readMask !== undefined) {
      obj.readMask = FieldMask.toJSON(FieldMask.wrap(message.readMask));
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestResultsRequest>): QueryTestResultsRequest {
    return QueryTestResultsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestResultsRequest>): QueryTestResultsRequest {
    const message = createBaseQueryTestResultsRequest() as any;
    message.invocations = object.invocations?.map((e) => e) || [];
    message.predicate = (object.predicate !== undefined && object.predicate !== null)
      ? TestResultPredicate.fromPartial(object.predicate)
      : undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.readMask = object.readMask ?? undefined;
    return message;
  },
};

function createBaseQueryTestResultsResponse(): QueryTestResultsResponse {
  return { testResults: [], nextPageToken: "" };
}

export const QueryTestResultsResponse: MessageFns<QueryTestResultsResponse> = {
  encode(message: QueryTestResultsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testResults) {
      TestResult.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestResultsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestResultsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testResults.push(TestResult.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestResultsResponse {
    return {
      testResults: globalThis.Array.isArray(object?.testResults)
        ? object.testResults.map((e: any) => TestResult.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestResultsResponse): unknown {
    const obj: any = {};
    if (message.testResults?.length) {
      obj.testResults = message.testResults.map((e) => TestResult.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestResultsResponse>): QueryTestResultsResponse {
    return QueryTestResultsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestResultsResponse>): QueryTestResultsResponse {
    const message = createBaseQueryTestResultsResponse() as any;
    message.testResults = object.testResults?.map((e) => TestResult.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryTestExonerationsRequest(): QueryTestExonerationsRequest {
  return { invocations: [], predicate: undefined, pageSize: 0, pageToken: "" };
}

export const QueryTestExonerationsRequest: MessageFns<QueryTestExonerationsRequest> = {
  encode(message: QueryTestExonerationsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.invocations) {
      writer.uint32(10).string(v!);
    }
    if (message.predicate !== undefined) {
      TestExonerationPredicate.encode(message.predicate, writer.uint32(18).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestExonerationsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestExonerationsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocations.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.predicate = TestExonerationPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestExonerationsRequest {
    return {
      invocations: globalThis.Array.isArray(object?.invocations)
        ? object.invocations.map((e: any) => globalThis.String(e))
        : [],
      predicate: isSet(object.predicate) ? TestExonerationPredicate.fromJSON(object.predicate) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryTestExonerationsRequest): unknown {
    const obj: any = {};
    if (message.invocations?.length) {
      obj.invocations = message.invocations;
    }
    if (message.predicate !== undefined) {
      obj.predicate = TestExonerationPredicate.toJSON(message.predicate);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestExonerationsRequest>): QueryTestExonerationsRequest {
    return QueryTestExonerationsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestExonerationsRequest>): QueryTestExonerationsRequest {
    const message = createBaseQueryTestExonerationsRequest() as any;
    message.invocations = object.invocations?.map((e) => e) || [];
    message.predicate = (object.predicate !== undefined && object.predicate !== null)
      ? TestExonerationPredicate.fromPartial(object.predicate)
      : undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryTestExonerationsResponse(): QueryTestExonerationsResponse {
  return { testExonerations: [], nextPageToken: "" };
}

export const QueryTestExonerationsResponse: MessageFns<QueryTestExonerationsResponse> = {
  encode(message: QueryTestExonerationsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testExonerations) {
      TestExoneration.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestExonerationsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestExonerationsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testExonerations.push(TestExoneration.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestExonerationsResponse {
    return {
      testExonerations: globalThis.Array.isArray(object?.testExonerations)
        ? object.testExonerations.map((e: any) => TestExoneration.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestExonerationsResponse): unknown {
    const obj: any = {};
    if (message.testExonerations?.length) {
      obj.testExonerations = message.testExonerations.map((e) => TestExoneration.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestExonerationsResponse>): QueryTestExonerationsResponse {
    return QueryTestExonerationsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestExonerationsResponse>): QueryTestExonerationsResponse {
    const message = createBaseQueryTestExonerationsResponse() as any;
    message.testExonerations = object.testExonerations?.map((e) => TestExoneration.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryTestResultStatisticsRequest(): QueryTestResultStatisticsRequest {
  return { invocations: [] };
}

export const QueryTestResultStatisticsRequest: MessageFns<QueryTestResultStatisticsRequest> = {
  encode(message: QueryTestResultStatisticsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.invocations) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestResultStatisticsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestResultStatisticsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocations.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestResultStatisticsRequest {
    return {
      invocations: globalThis.Array.isArray(object?.invocations)
        ? object.invocations.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: QueryTestResultStatisticsRequest): unknown {
    const obj: any = {};
    if (message.invocations?.length) {
      obj.invocations = message.invocations;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestResultStatisticsRequest>): QueryTestResultStatisticsRequest {
    return QueryTestResultStatisticsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestResultStatisticsRequest>): QueryTestResultStatisticsRequest {
    const message = createBaseQueryTestResultStatisticsRequest() as any;
    message.invocations = object.invocations?.map((e) => e) || [];
    return message;
  },
};

function createBaseQueryTestResultStatisticsResponse(): QueryTestResultStatisticsResponse {
  return { totalTestResults: "0" };
}

export const QueryTestResultStatisticsResponse: MessageFns<QueryTestResultStatisticsResponse> = {
  encode(message: QueryTestResultStatisticsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.totalTestResults !== "0") {
      writer.uint32(8).int64(message.totalTestResults);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestResultStatisticsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestResultStatisticsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.totalTestResults = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestResultStatisticsResponse {
    return { totalTestResults: isSet(object.totalTestResults) ? globalThis.String(object.totalTestResults) : "0" };
  },

  toJSON(message: QueryTestResultStatisticsResponse): unknown {
    const obj: any = {};
    if (message.totalTestResults !== "0") {
      obj.totalTestResults = message.totalTestResults;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestResultStatisticsResponse>): QueryTestResultStatisticsResponse {
    return QueryTestResultStatisticsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestResultStatisticsResponse>): QueryTestResultStatisticsResponse {
    const message = createBaseQueryTestResultStatisticsResponse() as any;
    message.totalTestResults = object.totalTestResults ?? "0";
    return message;
  },
};

function createBaseGetArtifactRequest(): GetArtifactRequest {
  return { name: "" };
}

export const GetArtifactRequest: MessageFns<GetArtifactRequest> = {
  encode(message: GetArtifactRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetArtifactRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetArtifactRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetArtifactRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetArtifactRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetArtifactRequest>): GetArtifactRequest {
    return GetArtifactRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetArtifactRequest>): GetArtifactRequest {
    const message = createBaseGetArtifactRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListArtifactsRequest(): ListArtifactsRequest {
  return { parent: "", pageSize: 0, pageToken: "" };
}

export const ListArtifactsRequest: MessageFns<ListArtifactsRequest> = {
  encode(message: ListArtifactsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(26).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListArtifactsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListArtifactsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListArtifactsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: ListArtifactsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListArtifactsRequest>): ListArtifactsRequest {
    return ListArtifactsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListArtifactsRequest>): ListArtifactsRequest {
    const message = createBaseListArtifactsRequest() as any;
    message.parent = object.parent ?? "";
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListArtifactsResponse(): ListArtifactsResponse {
  return { artifacts: [], nextPageToken: "" };
}

export const ListArtifactsResponse: MessageFns<ListArtifactsResponse> = {
  encode(message: ListArtifactsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.artifacts) {
      Artifact.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListArtifactsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListArtifactsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.artifacts.push(Artifact.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListArtifactsResponse {
    return {
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => Artifact.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListArtifactsResponse): unknown {
    const obj: any = {};
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => Artifact.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListArtifactsResponse>): ListArtifactsResponse {
    return ListArtifactsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListArtifactsResponse>): ListArtifactsResponse {
    const message = createBaseListArtifactsResponse() as any;
    message.artifacts = object.artifacts?.map((e) => Artifact.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryArtifactsRequest(): QueryArtifactsRequest {
  return { invocations: [], predicate: undefined, pageSize: 0, pageToken: "" };
}

export const QueryArtifactsRequest: MessageFns<QueryArtifactsRequest> = {
  encode(message: QueryArtifactsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.invocations) {
      writer.uint32(10).string(v!);
    }
    if (message.predicate !== undefined) {
      ArtifactPredicate.encode(message.predicate, writer.uint32(18).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryArtifactsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryArtifactsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocations.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.predicate = ArtifactPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryArtifactsRequest {
    return {
      invocations: globalThis.Array.isArray(object?.invocations)
        ? object.invocations.map((e: any) => globalThis.String(e))
        : [],
      predicate: isSet(object.predicate) ? ArtifactPredicate.fromJSON(object.predicate) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryArtifactsRequest): unknown {
    const obj: any = {};
    if (message.invocations?.length) {
      obj.invocations = message.invocations;
    }
    if (message.predicate !== undefined) {
      obj.predicate = ArtifactPredicate.toJSON(message.predicate);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryArtifactsRequest>): QueryArtifactsRequest {
    return QueryArtifactsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryArtifactsRequest>): QueryArtifactsRequest {
    const message = createBaseQueryArtifactsRequest() as any;
    message.invocations = object.invocations?.map((e) => e) || [];
    message.predicate = (object.predicate !== undefined && object.predicate !== null)
      ? ArtifactPredicate.fromPartial(object.predicate)
      : undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryArtifactsResponse(): QueryArtifactsResponse {
  return { artifacts: [], nextPageToken: "" };
}

export const QueryArtifactsResponse: MessageFns<QueryArtifactsResponse> = {
  encode(message: QueryArtifactsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.artifacts) {
      Artifact.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryArtifactsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryArtifactsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.artifacts.push(Artifact.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryArtifactsResponse {
    return {
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => Artifact.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryArtifactsResponse): unknown {
    const obj: any = {};
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => Artifact.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryArtifactsResponse>): QueryArtifactsResponse {
    return QueryArtifactsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryArtifactsResponse>): QueryArtifactsResponse {
    const message = createBaseQueryArtifactsResponse() as any;
    message.artifacts = object.artifacts?.map((e) => Artifact.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseListArtifactLinesRequest(): ListArtifactLinesRequest {
  return { parent: "", pageSize: 0 };
}

export const ListArtifactLinesRequest: MessageFns<ListArtifactLinesRequest> = {
  encode(message: ListArtifactLinesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListArtifactLinesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListArtifactLinesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListArtifactLinesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: ListArtifactLinesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create(base?: DeepPartial<ListArtifactLinesRequest>): ListArtifactLinesRequest {
    return ListArtifactLinesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListArtifactLinesRequest>): ListArtifactLinesRequest {
    const message = createBaseListArtifactLinesRequest() as any;
    message.parent = object.parent ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListArtifactLinesResponse(): ListArtifactLinesResponse {
  return { lines: [] };
}

export const ListArtifactLinesResponse: MessageFns<ListArtifactLinesResponse> = {
  encode(message: ListArtifactLinesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.lines) {
      ArtifactLine.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListArtifactLinesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListArtifactLinesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.lines.push(ArtifactLine.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListArtifactLinesResponse {
    return {
      lines: globalThis.Array.isArray(object?.lines) ? object.lines.map((e: any) => ArtifactLine.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListArtifactLinesResponse): unknown {
    const obj: any = {};
    if (message.lines?.length) {
      obj.lines = message.lines.map((e) => ArtifactLine.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ListArtifactLinesResponse>): ListArtifactLinesResponse {
    return ListArtifactLinesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListArtifactLinesResponse>): ListArtifactLinesResponse {
    const message = createBaseListArtifactLinesResponse() as any;
    message.lines = object.lines?.map((e) => ArtifactLine.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryArtifactFailureOnlyLinesRequest(): QueryArtifactFailureOnlyLinesRequest {
  return { parent: "", includeContent: false, pageSize: 0, pageToken: "" };
}

export const QueryArtifactFailureOnlyLinesRequest: MessageFns<QueryArtifactFailureOnlyLinesRequest> = {
  encode(message: QueryArtifactFailureOnlyLinesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.includeContent !== false) {
      writer.uint32(16).bool(message.includeContent);
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryArtifactFailureOnlyLinesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryArtifactFailureOnlyLinesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.includeContent = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryArtifactFailureOnlyLinesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      includeContent: isSet(object.includeContent) ? globalThis.Boolean(object.includeContent) : false,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryArtifactFailureOnlyLinesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.includeContent !== false) {
      obj.includeContent = message.includeContent;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryArtifactFailureOnlyLinesRequest>): QueryArtifactFailureOnlyLinesRequest {
    return QueryArtifactFailureOnlyLinesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryArtifactFailureOnlyLinesRequest>): QueryArtifactFailureOnlyLinesRequest {
    const message = createBaseQueryArtifactFailureOnlyLinesRequest() as any;
    message.parent = object.parent ?? "";
    message.includeContent = object.includeContent ?? false;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryArtifactFailureOnlyLinesResponse(): QueryArtifactFailureOnlyLinesResponse {
  return { failureOnlyLineRanges: [], nextPageToken: "" };
}

export const QueryArtifactFailureOnlyLinesResponse: MessageFns<QueryArtifactFailureOnlyLinesResponse> = {
  encode(message: QueryArtifactFailureOnlyLinesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.failureOnlyLineRanges) {
      QueryArtifactFailureOnlyLinesResponse_LineRange.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryArtifactFailureOnlyLinesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryArtifactFailureOnlyLinesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.failureOnlyLineRanges.push(
            QueryArtifactFailureOnlyLinesResponse_LineRange.decode(reader, reader.uint32()),
          );
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryArtifactFailureOnlyLinesResponse {
    return {
      failureOnlyLineRanges: globalThis.Array.isArray(object?.failureOnlyLineRanges)
        ? object.failureOnlyLineRanges.map((e: any) => QueryArtifactFailureOnlyLinesResponse_LineRange.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryArtifactFailureOnlyLinesResponse): unknown {
    const obj: any = {};
    if (message.failureOnlyLineRanges?.length) {
      obj.failureOnlyLineRanges = message.failureOnlyLineRanges.map((e) =>
        QueryArtifactFailureOnlyLinesResponse_LineRange.toJSON(e)
      );
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryArtifactFailureOnlyLinesResponse>): QueryArtifactFailureOnlyLinesResponse {
    return QueryArtifactFailureOnlyLinesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryArtifactFailureOnlyLinesResponse>): QueryArtifactFailureOnlyLinesResponse {
    const message = createBaseQueryArtifactFailureOnlyLinesResponse() as any;
    message.failureOnlyLineRanges =
      object.failureOnlyLineRanges?.map((e) => QueryArtifactFailureOnlyLinesResponse_LineRange.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryArtifactFailureOnlyLinesResponse_LineRange(): QueryArtifactFailureOnlyLinesResponse_LineRange {
  return { start: 0, end: 0, lines: [] };
}

export const QueryArtifactFailureOnlyLinesResponse_LineRange: MessageFns<
  QueryArtifactFailureOnlyLinesResponse_LineRange
> = {
  encode(
    message: QueryArtifactFailureOnlyLinesResponse_LineRange,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.start !== 0) {
      writer.uint32(8).int32(message.start);
    }
    if (message.end !== 0) {
      writer.uint32(16).int32(message.end);
    }
    for (const v of message.lines) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryArtifactFailureOnlyLinesResponse_LineRange {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryArtifactFailureOnlyLinesResponse_LineRange() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.start = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.end = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.lines.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryArtifactFailureOnlyLinesResponse_LineRange {
    return {
      start: isSet(object.start) ? globalThis.Number(object.start) : 0,
      end: isSet(object.end) ? globalThis.Number(object.end) : 0,
      lines: globalThis.Array.isArray(object?.lines) ? object.lines.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: QueryArtifactFailureOnlyLinesResponse_LineRange): unknown {
    const obj: any = {};
    if (message.start !== 0) {
      obj.start = Math.round(message.start);
    }
    if (message.end !== 0) {
      obj.end = Math.round(message.end);
    }
    if (message.lines?.length) {
      obj.lines = message.lines;
    }
    return obj;
  },

  create(
    base?: DeepPartial<QueryArtifactFailureOnlyLinesResponse_LineRange>,
  ): QueryArtifactFailureOnlyLinesResponse_LineRange {
    return QueryArtifactFailureOnlyLinesResponse_LineRange.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryArtifactFailureOnlyLinesResponse_LineRange>,
  ): QueryArtifactFailureOnlyLinesResponse_LineRange {
    const message = createBaseQueryArtifactFailureOnlyLinesResponse_LineRange() as any;
    message.start = object.start ?? 0;
    message.end = object.end ?? 0;
    message.lines = object.lines?.map((e) => e) || [];
    return message;
  },
};

function createBaseQueryTestVariantsRequest(): QueryTestVariantsRequest {
  return { invocations: [], predicate: undefined, resultLimit: 0, pageSize: 0, pageToken: "", readMask: undefined };
}

export const QueryTestVariantsRequest: MessageFns<QueryTestVariantsRequest> = {
  encode(message: QueryTestVariantsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.invocations) {
      writer.uint32(18).string(v!);
    }
    if (message.predicate !== undefined) {
      TestVariantPredicate.encode(message.predicate, writer.uint32(50).fork()).join();
    }
    if (message.resultLimit !== 0) {
      writer.uint32(64).int32(message.resultLimit);
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    if (message.readMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.readMask), writer.uint32(58).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.invocations.push(reader.string());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.predicate = TestVariantPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.resultLimit = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.readMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantsRequest {
    return {
      invocations: globalThis.Array.isArray(object?.invocations)
        ? object.invocations.map((e: any) => globalThis.String(e))
        : [],
      predicate: isSet(object.predicate) ? TestVariantPredicate.fromJSON(object.predicate) : undefined,
      resultLimit: isSet(object.resultLimit) ? globalThis.Number(object.resultLimit) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      readMask: isSet(object.readMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.readMask)) : undefined,
    };
  },

  toJSON(message: QueryTestVariantsRequest): unknown {
    const obj: any = {};
    if (message.invocations?.length) {
      obj.invocations = message.invocations;
    }
    if (message.predicate !== undefined) {
      obj.predicate = TestVariantPredicate.toJSON(message.predicate);
    }
    if (message.resultLimit !== 0) {
      obj.resultLimit = Math.round(message.resultLimit);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.readMask !== undefined) {
      obj.readMask = FieldMask.toJSON(FieldMask.wrap(message.readMask));
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantsRequest>): QueryTestVariantsRequest {
    return QueryTestVariantsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantsRequest>): QueryTestVariantsRequest {
    const message = createBaseQueryTestVariantsRequest() as any;
    message.invocations = object.invocations?.map((e) => e) || [];
    message.predicate = (object.predicate !== undefined && object.predicate !== null)
      ? TestVariantPredicate.fromPartial(object.predicate)
      : undefined;
    message.resultLimit = object.resultLimit ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.readMask = object.readMask ?? undefined;
    return message;
  },
};

function createBaseQueryTestVariantsResponse(): QueryTestVariantsResponse {
  return { testVariants: [], nextPageToken: "", sources: {} };
}

export const QueryTestVariantsResponse: MessageFns<QueryTestVariantsResponse> = {
  encode(message: QueryTestVariantsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testVariants) {
      TestVariant.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    Object.entries(message.sources).forEach(([key, value]) => {
      QueryTestVariantsResponse_SourcesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testVariants.push(TestVariant.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          const entry3 = QueryTestVariantsResponse_SourcesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.sources[entry3.key] = entry3.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantsResponse {
    return {
      testVariants: globalThis.Array.isArray(object?.testVariants)
        ? object.testVariants.map((e: any) => TestVariant.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
      sources: isObject(object.sources)
        ? Object.entries(object.sources).reduce<{ [key: string]: Sources }>((acc, [key, value]) => {
          acc[key] = Sources.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QueryTestVariantsResponse): unknown {
    const obj: any = {};
    if (message.testVariants?.length) {
      obj.testVariants = message.testVariants.map((e) => TestVariant.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    if (message.sources) {
      const entries = Object.entries(message.sources);
      if (entries.length > 0) {
        obj.sources = {};
        entries.forEach(([k, v]) => {
          obj.sources[k] = Sources.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantsResponse>): QueryTestVariantsResponse {
    return QueryTestVariantsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantsResponse>): QueryTestVariantsResponse {
    const message = createBaseQueryTestVariantsResponse() as any;
    message.testVariants = object.testVariants?.map((e) => TestVariant.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    message.sources = Object.entries(object.sources ?? {}).reduce<{ [key: string]: Sources }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Sources.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQueryTestVariantsResponse_SourcesEntry(): QueryTestVariantsResponse_SourcesEntry {
  return { key: "", value: undefined };
}

export const QueryTestVariantsResponse_SourcesEntry: MessageFns<QueryTestVariantsResponse_SourcesEntry> = {
  encode(message: QueryTestVariantsResponse_SourcesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Sources.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantsResponse_SourcesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantsResponse_SourcesEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = Sources.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantsResponse_SourcesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Sources.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: QueryTestVariantsResponse_SourcesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Sources.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantsResponse_SourcesEntry>): QueryTestVariantsResponse_SourcesEntry {
    return QueryTestVariantsResponse_SourcesEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantsResponse_SourcesEntry>): QueryTestVariantsResponse_SourcesEntry {
    const message = createBaseQueryTestVariantsResponse_SourcesEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Sources.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQueryRunTestVerdictsRequest(): QueryRunTestVerdictsRequest {
  return { invocation: "", resultLimit: 0, pageSize: 0, pageToken: "" };
}

export const QueryRunTestVerdictsRequest: MessageFns<QueryRunTestVerdictsRequest> = {
  encode(message: QueryRunTestVerdictsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocation !== "") {
      writer.uint32(10).string(message.invocation);
    }
    if (message.resultLimit !== 0) {
      writer.uint32(16).int32(message.resultLimit);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(34).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRunTestVerdictsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRunTestVerdictsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocation = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.resultLimit = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRunTestVerdictsRequest {
    return {
      invocation: isSet(object.invocation) ? globalThis.String(object.invocation) : "",
      resultLimit: isSet(object.resultLimit) ? globalThis.Number(object.resultLimit) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryRunTestVerdictsRequest): unknown {
    const obj: any = {};
    if (message.invocation !== "") {
      obj.invocation = message.invocation;
    }
    if (message.resultLimit !== 0) {
      obj.resultLimit = Math.round(message.resultLimit);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryRunTestVerdictsRequest>): QueryRunTestVerdictsRequest {
    return QueryRunTestVerdictsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryRunTestVerdictsRequest>): QueryRunTestVerdictsRequest {
    const message = createBaseQueryRunTestVerdictsRequest() as any;
    message.invocation = object.invocation ?? "";
    message.resultLimit = object.resultLimit ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryRunTestVerdictsResponse(): QueryRunTestVerdictsResponse {
  return { runTestVerdicts: [], nextPageToken: "" };
}

export const QueryRunTestVerdictsResponse: MessageFns<QueryRunTestVerdictsResponse> = {
  encode(message: QueryRunTestVerdictsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.runTestVerdicts) {
      RunTestVerdict.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRunTestVerdictsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRunTestVerdictsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.runTestVerdicts.push(RunTestVerdict.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryRunTestVerdictsResponse {
    return {
      runTestVerdicts: globalThis.Array.isArray(object?.runTestVerdicts)
        ? object.runTestVerdicts.map((e: any) => RunTestVerdict.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryRunTestVerdictsResponse): unknown {
    const obj: any = {};
    if (message.runTestVerdicts?.length) {
      obj.runTestVerdicts = message.runTestVerdicts.map((e) => RunTestVerdict.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryRunTestVerdictsResponse>): QueryRunTestVerdictsResponse {
    return QueryRunTestVerdictsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryRunTestVerdictsResponse>): QueryRunTestVerdictsResponse {
    const message = createBaseQueryRunTestVerdictsResponse() as any;
    message.runTestVerdicts = object.runTestVerdicts?.map((e) => RunTestVerdict.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseBatchGetTestVariantsRequest(): BatchGetTestVariantsRequest {
  return { invocation: "", testVariants: [], resultLimit: 0 };
}

export const BatchGetTestVariantsRequest: MessageFns<BatchGetTestVariantsRequest> = {
  encode(message: BatchGetTestVariantsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocation !== "") {
      writer.uint32(10).string(message.invocation);
    }
    for (const v of message.testVariants) {
      BatchGetTestVariantsRequest_TestVariantIdentifier.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.resultLimit !== 0) {
      writer.uint32(24).int32(message.resultLimit);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocation = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testVariants.push(BatchGetTestVariantsRequest_TestVariantIdentifier.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.resultLimit = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantsRequest {
    return {
      invocation: isSet(object.invocation) ? globalThis.String(object.invocation) : "",
      testVariants: globalThis.Array.isArray(object?.testVariants)
        ? object.testVariants.map((e: any) => BatchGetTestVariantsRequest_TestVariantIdentifier.fromJSON(e))
        : [],
      resultLimit: isSet(object.resultLimit) ? globalThis.Number(object.resultLimit) : 0,
    };
  },

  toJSON(message: BatchGetTestVariantsRequest): unknown {
    const obj: any = {};
    if (message.invocation !== "") {
      obj.invocation = message.invocation;
    }
    if (message.testVariants?.length) {
      obj.testVariants = message.testVariants.map((e) => BatchGetTestVariantsRequest_TestVariantIdentifier.toJSON(e));
    }
    if (message.resultLimit !== 0) {
      obj.resultLimit = Math.round(message.resultLimit);
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetTestVariantsRequest>): BatchGetTestVariantsRequest {
    return BatchGetTestVariantsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetTestVariantsRequest>): BatchGetTestVariantsRequest {
    const message = createBaseBatchGetTestVariantsRequest() as any;
    message.invocation = object.invocation ?? "";
    message.testVariants =
      object.testVariants?.map((e) => BatchGetTestVariantsRequest_TestVariantIdentifier.fromPartial(e)) || [];
    message.resultLimit = object.resultLimit ?? 0;
    return message;
  },
};

function createBaseBatchGetTestVariantsRequest_TestVariantIdentifier(): BatchGetTestVariantsRequest_TestVariantIdentifier {
  return { testId: "", variantHash: "" };
}

export const BatchGetTestVariantsRequest_TestVariantIdentifier: MessageFns<
  BatchGetTestVariantsRequest_TestVariantIdentifier
> = {
  encode(
    message: BatchGetTestVariantsRequest_TestVariantIdentifier,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.testId !== "") {
      writer.uint32(10).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(18).string(message.variantHash);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantsRequest_TestVariantIdentifier {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantsRequest_TestVariantIdentifier() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantsRequest_TestVariantIdentifier {
    return {
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
    };
  },

  toJSON(message: BatchGetTestVariantsRequest_TestVariantIdentifier): unknown {
    const obj: any = {};
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    return obj;
  },

  create(
    base?: DeepPartial<BatchGetTestVariantsRequest_TestVariantIdentifier>,
  ): BatchGetTestVariantsRequest_TestVariantIdentifier {
    return BatchGetTestVariantsRequest_TestVariantIdentifier.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<BatchGetTestVariantsRequest_TestVariantIdentifier>,
  ): BatchGetTestVariantsRequest_TestVariantIdentifier {
    const message = createBaseBatchGetTestVariantsRequest_TestVariantIdentifier() as any;
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    return message;
  },
};

function createBaseBatchGetTestVariantsResponse(): BatchGetTestVariantsResponse {
  return { testVariants: [], sources: {} };
}

export const BatchGetTestVariantsResponse: MessageFns<BatchGetTestVariantsResponse> = {
  encode(message: BatchGetTestVariantsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testVariants) {
      TestVariant.encode(v!, writer.uint32(10).fork()).join();
    }
    Object.entries(message.sources).forEach(([key, value]) => {
      BatchGetTestVariantsResponse_SourcesEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testVariants.push(TestVariant.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          const entry2 = BatchGetTestVariantsResponse_SourcesEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.sources[entry2.key] = entry2.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantsResponse {
    return {
      testVariants: globalThis.Array.isArray(object?.testVariants)
        ? object.testVariants.map((e: any) => TestVariant.fromJSON(e))
        : [],
      sources: isObject(object.sources)
        ? Object.entries(object.sources).reduce<{ [key: string]: Sources }>((acc, [key, value]) => {
          acc[key] = Sources.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: BatchGetTestVariantsResponse): unknown {
    const obj: any = {};
    if (message.testVariants?.length) {
      obj.testVariants = message.testVariants.map((e) => TestVariant.toJSON(e));
    }
    if (message.sources) {
      const entries = Object.entries(message.sources);
      if (entries.length > 0) {
        obj.sources = {};
        entries.forEach(([k, v]) => {
          obj.sources[k] = Sources.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetTestVariantsResponse>): BatchGetTestVariantsResponse {
    return BatchGetTestVariantsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetTestVariantsResponse>): BatchGetTestVariantsResponse {
    const message = createBaseBatchGetTestVariantsResponse() as any;
    message.testVariants = object.testVariants?.map((e) => TestVariant.fromPartial(e)) || [];
    message.sources = Object.entries(object.sources ?? {}).reduce<{ [key: string]: Sources }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Sources.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseBatchGetTestVariantsResponse_SourcesEntry(): BatchGetTestVariantsResponse_SourcesEntry {
  return { key: "", value: undefined };
}

export const BatchGetTestVariantsResponse_SourcesEntry: MessageFns<BatchGetTestVariantsResponse_SourcesEntry> = {
  encode(message: BatchGetTestVariantsResponse_SourcesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Sources.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetTestVariantsResponse_SourcesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetTestVariantsResponse_SourcesEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = Sources.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetTestVariantsResponse_SourcesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Sources.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: BatchGetTestVariantsResponse_SourcesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Sources.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetTestVariantsResponse_SourcesEntry>): BatchGetTestVariantsResponse_SourcesEntry {
    return BatchGetTestVariantsResponse_SourcesEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<BatchGetTestVariantsResponse_SourcesEntry>,
  ): BatchGetTestVariantsResponse_SourcesEntry {
    const message = createBaseBatchGetTestVariantsResponse_SourcesEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Sources.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQueryTestMetadataRequest(): QueryTestMetadataRequest {
  return { project: "", predicate: undefined, pageSize: 0, pageToken: "" };
}

export const QueryTestMetadataRequest: MessageFns<QueryTestMetadataRequest> = {
  encode(message: QueryTestMetadataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.predicate !== undefined) {
      TestMetadataPredicate.encode(message.predicate, writer.uint32(18).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(34).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestMetadataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestMetadataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.predicate = TestMetadataPredicate.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestMetadataRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      predicate: isSet(object.predicate) ? TestMetadataPredicate.fromJSON(object.predicate) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryTestMetadataRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.predicate !== undefined) {
      obj.predicate = TestMetadataPredicate.toJSON(message.predicate);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestMetadataRequest>): QueryTestMetadataRequest {
    return QueryTestMetadataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestMetadataRequest>): QueryTestMetadataRequest {
    const message = createBaseQueryTestMetadataRequest() as any;
    message.project = object.project ?? "";
    message.predicate = (object.predicate !== undefined && object.predicate !== null)
      ? TestMetadataPredicate.fromPartial(object.predicate)
      : undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryTestMetadataResponse(): QueryTestMetadataResponse {
  return { testMetadata: [], nextPageToken: "" };
}

export const QueryTestMetadataResponse: MessageFns<QueryTestMetadataResponse> = {
  encode(message: QueryTestMetadataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.testMetadata) {
      TestMetadataDetail.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestMetadataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestMetadataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testMetadata.push(TestMetadataDetail.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestMetadataResponse {
    return {
      testMetadata: globalThis.Array.isArray(object?.testMetadata)
        ? object.testMetadata.map((e: any) => TestMetadataDetail.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestMetadataResponse): unknown {
    const obj: any = {};
    if (message.testMetadata?.length) {
      obj.testMetadata = message.testMetadata.map((e) => TestMetadataDetail.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestMetadataResponse>): QueryTestMetadataResponse {
    return QueryTestMetadataResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestMetadataResponse>): QueryTestMetadataResponse {
    const message = createBaseQueryTestMetadataResponse() as any;
    message.testMetadata = object.testMetadata?.map((e) => TestMetadataDetail.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryNewTestVariantsRequest(): QueryNewTestVariantsRequest {
  return { invocation: "", baseline: "" };
}

export const QueryNewTestVariantsRequest: MessageFns<QueryNewTestVariantsRequest> = {
  encode(message: QueryNewTestVariantsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.invocation !== "") {
      writer.uint32(10).string(message.invocation);
    }
    if (message.baseline !== "") {
      writer.uint32(18).string(message.baseline);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryNewTestVariantsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryNewTestVariantsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.invocation = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.baseline = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryNewTestVariantsRequest {
    return {
      invocation: isSet(object.invocation) ? globalThis.String(object.invocation) : "",
      baseline: isSet(object.baseline) ? globalThis.String(object.baseline) : "",
    };
  },

  toJSON(message: QueryNewTestVariantsRequest): unknown {
    const obj: any = {};
    if (message.invocation !== "") {
      obj.invocation = message.invocation;
    }
    if (message.baseline !== "") {
      obj.baseline = message.baseline;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryNewTestVariantsRequest>): QueryNewTestVariantsRequest {
    return QueryNewTestVariantsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryNewTestVariantsRequest>): QueryNewTestVariantsRequest {
    const message = createBaseQueryNewTestVariantsRequest() as any;
    message.invocation = object.invocation ?? "";
    message.baseline = object.baseline ?? "";
    return message;
  },
};

function createBaseQueryNewTestVariantsResponse(): QueryNewTestVariantsResponse {
  return { isBaselineReady: false, newTestVariants: [] };
}

export const QueryNewTestVariantsResponse: MessageFns<QueryNewTestVariantsResponse> = {
  encode(message: QueryNewTestVariantsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.isBaselineReady !== false) {
      writer.uint32(8).bool(message.isBaselineReady);
    }
    for (const v of message.newTestVariants) {
      QueryNewTestVariantsResponse_NewTestVariant.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryNewTestVariantsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryNewTestVariantsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.isBaselineReady = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newTestVariants.push(QueryNewTestVariantsResponse_NewTestVariant.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryNewTestVariantsResponse {
    return {
      isBaselineReady: isSet(object.isBaselineReady) ? globalThis.Boolean(object.isBaselineReady) : false,
      newTestVariants: globalThis.Array.isArray(object?.newTestVariants)
        ? object.newTestVariants.map((e: any) => QueryNewTestVariantsResponse_NewTestVariant.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QueryNewTestVariantsResponse): unknown {
    const obj: any = {};
    if (message.isBaselineReady !== false) {
      obj.isBaselineReady = message.isBaselineReady;
    }
    if (message.newTestVariants?.length) {
      obj.newTestVariants = message.newTestVariants.map((e) => QueryNewTestVariantsResponse_NewTestVariant.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QueryNewTestVariantsResponse>): QueryNewTestVariantsResponse {
    return QueryNewTestVariantsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryNewTestVariantsResponse>): QueryNewTestVariantsResponse {
    const message = createBaseQueryNewTestVariantsResponse() as any;
    message.isBaselineReady = object.isBaselineReady ?? false;
    message.newTestVariants =
      object.newTestVariants?.map((e) => QueryNewTestVariantsResponse_NewTestVariant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryNewTestVariantsResponse_NewTestVariant(): QueryNewTestVariantsResponse_NewTestVariant {
  return { testId: "", variantHash: "" };
}

export const QueryNewTestVariantsResponse_NewTestVariant: MessageFns<QueryNewTestVariantsResponse_NewTestVariant> = {
  encode(
    message: QueryNewTestVariantsResponse_NewTestVariant,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.testId !== "") {
      writer.uint32(10).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(18).string(message.variantHash);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryNewTestVariantsResponse_NewTestVariant {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryNewTestVariantsResponse_NewTestVariant() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryNewTestVariantsResponse_NewTestVariant {
    return {
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
    };
  },

  toJSON(message: QueryNewTestVariantsResponse_NewTestVariant): unknown {
    const obj: any = {};
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryNewTestVariantsResponse_NewTestVariant>): QueryNewTestVariantsResponse_NewTestVariant {
    return QueryNewTestVariantsResponse_NewTestVariant.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryNewTestVariantsResponse_NewTestVariant>,
  ): QueryNewTestVariantsResponse_NewTestVariant {
    const message = createBaseQueryNewTestVariantsResponse_NewTestVariant() as any;
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    return message;
  },
};

function createBaseGetInstructionRequest(): GetInstructionRequest {
  return { name: "" };
}

export const GetInstructionRequest: MessageFns<GetInstructionRequest> = {
  encode(message: GetInstructionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetInstructionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetInstructionRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetInstructionRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetInstructionRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetInstructionRequest>): GetInstructionRequest {
    return GetInstructionRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetInstructionRequest>): GetInstructionRequest {
    const message = createBaseGetInstructionRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseQueryInstructionRequest(): QueryInstructionRequest {
  return { name: "", dependencyMaxDepth: 0 };
}

export const QueryInstructionRequest: MessageFns<QueryInstructionRequest> = {
  encode(message: QueryInstructionRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.dependencyMaxDepth !== 0) {
      writer.uint32(16).int32(message.dependencyMaxDepth);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInstructionRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInstructionRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.dependencyMaxDepth = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInstructionRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      dependencyMaxDepth: isSet(object.dependencyMaxDepth) ? globalThis.Number(object.dependencyMaxDepth) : 0,
    };
  },

  toJSON(message: QueryInstructionRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.dependencyMaxDepth !== 0) {
      obj.dependencyMaxDepth = Math.round(message.dependencyMaxDepth);
    }
    return obj;
  },

  create(base?: DeepPartial<QueryInstructionRequest>): QueryInstructionRequest {
    return QueryInstructionRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryInstructionRequest>): QueryInstructionRequest {
    const message = createBaseQueryInstructionRequest() as any;
    message.name = object.name ?? "";
    message.dependencyMaxDepth = object.dependencyMaxDepth ?? 0;
    return message;
  },
};

function createBaseQueryInstructionResponse(): QueryInstructionResponse {
  return { instruction: undefined, dependencyChains: [] };
}

export const QueryInstructionResponse: MessageFns<QueryInstructionResponse> = {
  encode(message: QueryInstructionResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.instruction !== undefined) {
      Instruction.encode(message.instruction, writer.uint32(10).fork()).join();
    }
    for (const v of message.dependencyChains) {
      InstructionDependencyChain.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInstructionResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInstructionResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.instruction = Instruction.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dependencyChains.push(InstructionDependencyChain.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInstructionResponse {
    return {
      instruction: isSet(object.instruction) ? Instruction.fromJSON(object.instruction) : undefined,
      dependencyChains: globalThis.Array.isArray(object?.dependencyChains)
        ? object.dependencyChains.map((e: any) => InstructionDependencyChain.fromJSON(e))
        : [],
    };
  },

  toJSON(message: QueryInstructionResponse): unknown {
    const obj: any = {};
    if (message.instruction !== undefined) {
      obj.instruction = Instruction.toJSON(message.instruction);
    }
    if (message.dependencyChains?.length) {
      obj.dependencyChains = message.dependencyChains.map((e) => InstructionDependencyChain.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<QueryInstructionResponse>): QueryInstructionResponse {
    return QueryInstructionResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryInstructionResponse>): QueryInstructionResponse {
    const message = createBaseQueryInstructionResponse() as any;
    message.instruction = (object.instruction !== undefined && object.instruction !== null)
      ? Instruction.fromPartial(object.instruction)
      : undefined;
    message.dependencyChains = object.dependencyChains?.map((e) => InstructionDependencyChain.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInstructionDependencyChain(): InstructionDependencyChain {
  return { target: 0, nodes: [] };
}

export const InstructionDependencyChain: MessageFns<InstructionDependencyChain> = {
  encode(message: InstructionDependencyChain, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.target !== 0) {
      writer.uint32(8).int32(message.target);
    }
    for (const v of message.nodes) {
      InstructionDependencyChain_Node.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InstructionDependencyChain {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructionDependencyChain() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.target = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nodes.push(InstructionDependencyChain_Node.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstructionDependencyChain {
    return {
      target: isSet(object.target) ? instructionTargetFromJSON(object.target) : 0,
      nodes: globalThis.Array.isArray(object?.nodes)
        ? object.nodes.map((e: any) => InstructionDependencyChain_Node.fromJSON(e))
        : [],
    };
  },

  toJSON(message: InstructionDependencyChain): unknown {
    const obj: any = {};
    if (message.target !== 0) {
      obj.target = instructionTargetToJSON(message.target);
    }
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => InstructionDependencyChain_Node.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<InstructionDependencyChain>): InstructionDependencyChain {
    return InstructionDependencyChain.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InstructionDependencyChain>): InstructionDependencyChain {
    const message = createBaseInstructionDependencyChain() as any;
    message.target = object.target ?? 0;
    message.nodes = object.nodes?.map((e) => InstructionDependencyChain_Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInstructionDependencyChain_Node(): InstructionDependencyChain_Node {
  return { instructionName: "", content: "", error: "", descriptiveName: "" };
}

export const InstructionDependencyChain_Node: MessageFns<InstructionDependencyChain_Node> = {
  encode(message: InstructionDependencyChain_Node, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.instructionName !== "") {
      writer.uint32(10).string(message.instructionName);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.error !== "") {
      writer.uint32(26).string(message.error);
    }
    if (message.descriptiveName !== "") {
      writer.uint32(34).string(message.descriptiveName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InstructionDependencyChain_Node {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstructionDependencyChain_Node() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.instructionName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.error = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.descriptiveName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstructionDependencyChain_Node {
    return {
      instructionName: isSet(object.instructionName) ? globalThis.String(object.instructionName) : "",
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      descriptiveName: isSet(object.descriptiveName) ? globalThis.String(object.descriptiveName) : "",
    };
  },

  toJSON(message: InstructionDependencyChain_Node): unknown {
    const obj: any = {};
    if (message.instructionName !== "") {
      obj.instructionName = message.instructionName;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.descriptiveName !== "") {
      obj.descriptiveName = message.descriptiveName;
    }
    return obj;
  },

  create(base?: DeepPartial<InstructionDependencyChain_Node>): InstructionDependencyChain_Node {
    return InstructionDependencyChain_Node.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InstructionDependencyChain_Node>): InstructionDependencyChain_Node {
    const message = createBaseInstructionDependencyChain_Node() as any;
    message.instructionName = object.instructionName ?? "";
    message.content = object.content ?? "";
    message.error = object.error ?? "";
    message.descriptiveName = object.descriptiveName ?? "";
    return message;
  },
};

function createBaseQueryTestVariantArtifactGroupsRequest(): QueryTestVariantArtifactGroupsRequest {
  return {
    project: "",
    searchString: undefined,
    testIdMatcher: undefined,
    artifactIdMatcher: undefined,
    startTime: undefined,
    endTime: undefined,
    pageSize: 0,
    pageToken: "",
  };
}

export const QueryTestVariantArtifactGroupsRequest: MessageFns<QueryTestVariantArtifactGroupsRequest> = {
  encode(message: QueryTestVariantArtifactGroupsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.searchString !== undefined) {
      ArtifactContentMatcher.encode(message.searchString, writer.uint32(18).fork()).join();
    }
    if (message.testIdMatcher !== undefined) {
      IDMatcher.encode(message.testIdMatcher, writer.uint32(26).fork()).join();
    }
    if (message.artifactIdMatcher !== undefined) {
      IDMatcher.encode(message.artifactIdMatcher, writer.uint32(34).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(42).fork()).join();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(50).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(56).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(66).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantArtifactGroupsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantArtifactGroupsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.searchString = ArtifactContentMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.testIdMatcher = IDMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.artifactIdMatcher = IDMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantArtifactGroupsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      searchString: isSet(object.searchString) ? ArtifactContentMatcher.fromJSON(object.searchString) : undefined,
      testIdMatcher: isSet(object.testIdMatcher) ? IDMatcher.fromJSON(object.testIdMatcher) : undefined,
      artifactIdMatcher: isSet(object.artifactIdMatcher) ? IDMatcher.fromJSON(object.artifactIdMatcher) : undefined,
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantArtifactGroupsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.searchString !== undefined) {
      obj.searchString = ArtifactContentMatcher.toJSON(message.searchString);
    }
    if (message.testIdMatcher !== undefined) {
      obj.testIdMatcher = IDMatcher.toJSON(message.testIdMatcher);
    }
    if (message.artifactIdMatcher !== undefined) {
      obj.artifactIdMatcher = IDMatcher.toJSON(message.artifactIdMatcher);
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantArtifactGroupsRequest>): QueryTestVariantArtifactGroupsRequest {
    return QueryTestVariantArtifactGroupsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantArtifactGroupsRequest>): QueryTestVariantArtifactGroupsRequest {
    const message = createBaseQueryTestVariantArtifactGroupsRequest() as any;
    message.project = object.project ?? "";
    message.searchString = (object.searchString !== undefined && object.searchString !== null)
      ? ArtifactContentMatcher.fromPartial(object.searchString)
      : undefined;
    message.testIdMatcher = (object.testIdMatcher !== undefined && object.testIdMatcher !== null)
      ? IDMatcher.fromPartial(object.testIdMatcher)
      : undefined;
    message.artifactIdMatcher = (object.artifactIdMatcher !== undefined && object.artifactIdMatcher !== null)
      ? IDMatcher.fromPartial(object.artifactIdMatcher)
      : undefined;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryTestVariantArtifactGroupsResponse(): QueryTestVariantArtifactGroupsResponse {
  return { groups: [], nextPageToken: "" };
}

export const QueryTestVariantArtifactGroupsResponse: MessageFns<QueryTestVariantArtifactGroupsResponse> = {
  encode(message: QueryTestVariantArtifactGroupsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.groups) {
      QueryTestVariantArtifactGroupsResponse_MatchGroup.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantArtifactGroupsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantArtifactGroupsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.groups.push(QueryTestVariantArtifactGroupsResponse_MatchGroup.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantArtifactGroupsResponse {
    return {
      groups: globalThis.Array.isArray(object?.groups)
        ? object.groups.map((e: any) => QueryTestVariantArtifactGroupsResponse_MatchGroup.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantArtifactGroupsResponse): unknown {
    const obj: any = {};
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => QueryTestVariantArtifactGroupsResponse_MatchGroup.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantArtifactGroupsResponse>): QueryTestVariantArtifactGroupsResponse {
    return QueryTestVariantArtifactGroupsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantArtifactGroupsResponse>): QueryTestVariantArtifactGroupsResponse {
    const message = createBaseQueryTestVariantArtifactGroupsResponse() as any;
    message.groups = object.groups?.map((e) => QueryTestVariantArtifactGroupsResponse_MatchGroup.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryTestVariantArtifactGroupsResponse_MatchGroup(): QueryTestVariantArtifactGroupsResponse_MatchGroup {
  return { testId: "", variantHash: "", variant: undefined, artifactId: "", artifacts: [], matchingCount: 0 };
}

export const QueryTestVariantArtifactGroupsResponse_MatchGroup: MessageFns<
  QueryTestVariantArtifactGroupsResponse_MatchGroup
> = {
  encode(
    message: QueryTestVariantArtifactGroupsResponse_MatchGroup,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.testId !== "") {
      writer.uint32(10).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(18).string(message.variantHash);
    }
    if (message.variant !== undefined) {
      Variant.encode(message.variant, writer.uint32(26).fork()).join();
    }
    if (message.artifactId !== "") {
      writer.uint32(34).string(message.artifactId);
    }
    for (const v of message.artifacts) {
      ArtifactMatchingContent.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.matchingCount !== 0) {
      writer.uint32(48).int32(message.matchingCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantArtifactGroupsResponse_MatchGroup {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantArtifactGroupsResponse_MatchGroup() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.variant = Variant.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.artifactId = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.artifacts.push(ArtifactMatchingContent.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.matchingCount = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantArtifactGroupsResponse_MatchGroup {
    return {
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      variant: isSet(object.variant) ? Variant.fromJSON(object.variant) : undefined,
      artifactId: isSet(object.artifactId) ? globalThis.String(object.artifactId) : "",
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => ArtifactMatchingContent.fromJSON(e))
        : [],
      matchingCount: isSet(object.matchingCount) ? globalThis.Number(object.matchingCount) : 0,
    };
  },

  toJSON(message: QueryTestVariantArtifactGroupsResponse_MatchGroup): unknown {
    const obj: any = {};
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.variant !== undefined) {
      obj.variant = Variant.toJSON(message.variant);
    }
    if (message.artifactId !== "") {
      obj.artifactId = message.artifactId;
    }
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => ArtifactMatchingContent.toJSON(e));
    }
    if (message.matchingCount !== 0) {
      obj.matchingCount = Math.round(message.matchingCount);
    }
    return obj;
  },

  create(
    base?: DeepPartial<QueryTestVariantArtifactGroupsResponse_MatchGroup>,
  ): QueryTestVariantArtifactGroupsResponse_MatchGroup {
    return QueryTestVariantArtifactGroupsResponse_MatchGroup.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryTestVariantArtifactGroupsResponse_MatchGroup>,
  ): QueryTestVariantArtifactGroupsResponse_MatchGroup {
    const message = createBaseQueryTestVariantArtifactGroupsResponse_MatchGroup() as any;
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.variant = (object.variant !== undefined && object.variant !== null)
      ? Variant.fromPartial(object.variant)
      : undefined;
    message.artifactId = object.artifactId ?? "";
    message.artifacts = object.artifacts?.map((e) => ArtifactMatchingContent.fromPartial(e)) || [];
    message.matchingCount = object.matchingCount ?? 0;
    return message;
  },
};

function createBaseQueryTestVariantArtifactsRequest(): QueryTestVariantArtifactsRequest {
  return {
    project: "",
    searchString: undefined,
    testId: "",
    variantHash: "",
    artifactId: "",
    startTime: undefined,
    endTime: undefined,
    pageSize: 0,
    pageToken: "",
  };
}

export const QueryTestVariantArtifactsRequest: MessageFns<QueryTestVariantArtifactsRequest> = {
  encode(message: QueryTestVariantArtifactsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.searchString !== undefined) {
      ArtifactContentMatcher.encode(message.searchString, writer.uint32(18).fork()).join();
    }
    if (message.testId !== "") {
      writer.uint32(26).string(message.testId);
    }
    if (message.variantHash !== "") {
      writer.uint32(34).string(message.variantHash);
    }
    if (message.artifactId !== "") {
      writer.uint32(42).string(message.artifactId);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(50).fork()).join();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(58).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(64).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(74).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantArtifactsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantArtifactsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.searchString = ArtifactContentMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.testId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.variantHash = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.artifactId = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantArtifactsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      searchString: isSet(object.searchString) ? ArtifactContentMatcher.fromJSON(object.searchString) : undefined,
      testId: isSet(object.testId) ? globalThis.String(object.testId) : "",
      variantHash: isSet(object.variantHash) ? globalThis.String(object.variantHash) : "",
      artifactId: isSet(object.artifactId) ? globalThis.String(object.artifactId) : "",
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantArtifactsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.searchString !== undefined) {
      obj.searchString = ArtifactContentMatcher.toJSON(message.searchString);
    }
    if (message.testId !== "") {
      obj.testId = message.testId;
    }
    if (message.variantHash !== "") {
      obj.variantHash = message.variantHash;
    }
    if (message.artifactId !== "") {
      obj.artifactId = message.artifactId;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantArtifactsRequest>): QueryTestVariantArtifactsRequest {
    return QueryTestVariantArtifactsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantArtifactsRequest>): QueryTestVariantArtifactsRequest {
    const message = createBaseQueryTestVariantArtifactsRequest() as any;
    message.project = object.project ?? "";
    message.searchString = (object.searchString !== undefined && object.searchString !== null)
      ? ArtifactContentMatcher.fromPartial(object.searchString)
      : undefined;
    message.testId = object.testId ?? "";
    message.variantHash = object.variantHash ?? "";
    message.artifactId = object.artifactId ?? "";
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryTestVariantArtifactsResponse(): QueryTestVariantArtifactsResponse {
  return { artifacts: [], nextPageToken: "" };
}

export const QueryTestVariantArtifactsResponse: MessageFns<QueryTestVariantArtifactsResponse> = {
  encode(message: QueryTestVariantArtifactsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.artifacts) {
      ArtifactMatchingContent.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryTestVariantArtifactsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTestVariantArtifactsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.artifacts.push(ArtifactMatchingContent.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryTestVariantArtifactsResponse {
    return {
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => ArtifactMatchingContent.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryTestVariantArtifactsResponse): unknown {
    const obj: any = {};
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => ArtifactMatchingContent.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryTestVariantArtifactsResponse>): QueryTestVariantArtifactsResponse {
    return QueryTestVariantArtifactsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryTestVariantArtifactsResponse>): QueryTestVariantArtifactsResponse {
    const message = createBaseQueryTestVariantArtifactsResponse() as any;
    message.artifacts = object.artifacts?.map((e) => ArtifactMatchingContent.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryInvocationVariantArtifactGroupsRequest(): QueryInvocationVariantArtifactGroupsRequest {
  return {
    project: "",
    searchString: undefined,
    artifactIdMatcher: undefined,
    startTime: undefined,
    endTime: undefined,
    pageSize: 0,
    pageToken: "",
  };
}

export const QueryInvocationVariantArtifactGroupsRequest: MessageFns<QueryInvocationVariantArtifactGroupsRequest> = {
  encode(
    message: QueryInvocationVariantArtifactGroupsRequest,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.searchString !== undefined) {
      ArtifactContentMatcher.encode(message.searchString, writer.uint32(18).fork()).join();
    }
    if (message.artifactIdMatcher !== undefined) {
      IDMatcher.encode(message.artifactIdMatcher, writer.uint32(26).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(34).fork()).join();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(42).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(48).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(58).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInvocationVariantArtifactGroupsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInvocationVariantArtifactGroupsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.searchString = ArtifactContentMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.artifactIdMatcher = IDMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInvocationVariantArtifactGroupsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      searchString: isSet(object.searchString) ? ArtifactContentMatcher.fromJSON(object.searchString) : undefined,
      artifactIdMatcher: isSet(object.artifactIdMatcher) ? IDMatcher.fromJSON(object.artifactIdMatcher) : undefined,
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryInvocationVariantArtifactGroupsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.searchString !== undefined) {
      obj.searchString = ArtifactContentMatcher.toJSON(message.searchString);
    }
    if (message.artifactIdMatcher !== undefined) {
      obj.artifactIdMatcher = IDMatcher.toJSON(message.artifactIdMatcher);
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryInvocationVariantArtifactGroupsRequest>): QueryInvocationVariantArtifactGroupsRequest {
    return QueryInvocationVariantArtifactGroupsRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryInvocationVariantArtifactGroupsRequest>,
  ): QueryInvocationVariantArtifactGroupsRequest {
    const message = createBaseQueryInvocationVariantArtifactGroupsRequest() as any;
    message.project = object.project ?? "";
    message.searchString = (object.searchString !== undefined && object.searchString !== null)
      ? ArtifactContentMatcher.fromPartial(object.searchString)
      : undefined;
    message.artifactIdMatcher = (object.artifactIdMatcher !== undefined && object.artifactIdMatcher !== null)
      ? IDMatcher.fromPartial(object.artifactIdMatcher)
      : undefined;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryInvocationVariantArtifactGroupsResponse(): QueryInvocationVariantArtifactGroupsResponse {
  return { groups: [], nextPageToken: "" };
}

export const QueryInvocationVariantArtifactGroupsResponse: MessageFns<QueryInvocationVariantArtifactGroupsResponse> = {
  encode(
    message: QueryInvocationVariantArtifactGroupsResponse,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    for (const v of message.groups) {
      QueryInvocationVariantArtifactGroupsResponse_MatchGroup.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInvocationVariantArtifactGroupsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInvocationVariantArtifactGroupsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.groups.push(QueryInvocationVariantArtifactGroupsResponse_MatchGroup.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInvocationVariantArtifactGroupsResponse {
    return {
      groups: globalThis.Array.isArray(object?.groups)
        ? object.groups.map((e: any) => QueryInvocationVariantArtifactGroupsResponse_MatchGroup.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryInvocationVariantArtifactGroupsResponse): unknown {
    const obj: any = {};
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => QueryInvocationVariantArtifactGroupsResponse_MatchGroup.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(
    base?: DeepPartial<QueryInvocationVariantArtifactGroupsResponse>,
  ): QueryInvocationVariantArtifactGroupsResponse {
    return QueryInvocationVariantArtifactGroupsResponse.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryInvocationVariantArtifactGroupsResponse>,
  ): QueryInvocationVariantArtifactGroupsResponse {
    const message = createBaseQueryInvocationVariantArtifactGroupsResponse() as any;
    message.groups =
      object.groups?.map((e) => QueryInvocationVariantArtifactGroupsResponse_MatchGroup.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseQueryInvocationVariantArtifactGroupsResponse_MatchGroup(): QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
  return { variantUnionHash: "", variantUnion: undefined, artifactId: "", artifacts: [], matchingCount: 0 };
}

export const QueryInvocationVariantArtifactGroupsResponse_MatchGroup: MessageFns<
  QueryInvocationVariantArtifactGroupsResponse_MatchGroup
> = {
  encode(
    message: QueryInvocationVariantArtifactGroupsResponse_MatchGroup,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.variantUnionHash !== "") {
      writer.uint32(10).string(message.variantUnionHash);
    }
    if (message.variantUnion !== undefined) {
      Variant.encode(message.variantUnion, writer.uint32(18).fork()).join();
    }
    if (message.artifactId !== "") {
      writer.uint32(26).string(message.artifactId);
    }
    for (const v of message.artifacts) {
      ArtifactMatchingContent.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.matchingCount !== 0) {
      writer.uint32(40).int32(message.matchingCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInvocationVariantArtifactGroupsResponse_MatchGroup() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.variantUnionHash = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.variantUnion = Variant.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.artifactId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.artifacts.push(ArtifactMatchingContent.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.matchingCount = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
    return {
      variantUnionHash: isSet(object.variantUnionHash) ? globalThis.String(object.variantUnionHash) : "",
      variantUnion: isSet(object.variantUnion) ? Variant.fromJSON(object.variantUnion) : undefined,
      artifactId: isSet(object.artifactId) ? globalThis.String(object.artifactId) : "",
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => ArtifactMatchingContent.fromJSON(e))
        : [],
      matchingCount: isSet(object.matchingCount) ? globalThis.Number(object.matchingCount) : 0,
    };
  },

  toJSON(message: QueryInvocationVariantArtifactGroupsResponse_MatchGroup): unknown {
    const obj: any = {};
    if (message.variantUnionHash !== "") {
      obj.variantUnionHash = message.variantUnionHash;
    }
    if (message.variantUnion !== undefined) {
      obj.variantUnion = Variant.toJSON(message.variantUnion);
    }
    if (message.artifactId !== "") {
      obj.artifactId = message.artifactId;
    }
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => ArtifactMatchingContent.toJSON(e));
    }
    if (message.matchingCount !== 0) {
      obj.matchingCount = Math.round(message.matchingCount);
    }
    return obj;
  },

  create(
    base?: DeepPartial<QueryInvocationVariantArtifactGroupsResponse_MatchGroup>,
  ): QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
    return QueryInvocationVariantArtifactGroupsResponse_MatchGroup.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<QueryInvocationVariantArtifactGroupsResponse_MatchGroup>,
  ): QueryInvocationVariantArtifactGroupsResponse_MatchGroup {
    const message = createBaseQueryInvocationVariantArtifactGroupsResponse_MatchGroup() as any;
    message.variantUnionHash = object.variantUnionHash ?? "";
    message.variantUnion = (object.variantUnion !== undefined && object.variantUnion !== null)
      ? Variant.fromPartial(object.variantUnion)
      : undefined;
    message.artifactId = object.artifactId ?? "";
    message.artifacts = object.artifacts?.map((e) => ArtifactMatchingContent.fromPartial(e)) || [];
    message.matchingCount = object.matchingCount ?? 0;
    return message;
  },
};

function createBaseQueryInvocationVariantArtifactsRequest(): QueryInvocationVariantArtifactsRequest {
  return {
    project: "",
    searchString: undefined,
    variantUnionHash: "",
    artifactId: "",
    startTime: undefined,
    endTime: undefined,
    pageSize: 0,
    pageToken: "",
  };
}

export const QueryInvocationVariantArtifactsRequest: MessageFns<QueryInvocationVariantArtifactsRequest> = {
  encode(message: QueryInvocationVariantArtifactsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.searchString !== undefined) {
      ArtifactContentMatcher.encode(message.searchString, writer.uint32(18).fork()).join();
    }
    if (message.variantUnionHash !== "") {
      writer.uint32(26).string(message.variantUnionHash);
    }
    if (message.artifactId !== "") {
      writer.uint32(34).string(message.artifactId);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(42).fork()).join();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(50).fork()).join();
    }
    if (message.pageSize !== 0) {
      writer.uint32(56).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(66).string(message.pageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInvocationVariantArtifactsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInvocationVariantArtifactsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.searchString = ArtifactContentMatcher.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.variantUnionHash = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.artifactId = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInvocationVariantArtifactsRequest {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      searchString: isSet(object.searchString) ? ArtifactContentMatcher.fromJSON(object.searchString) : undefined,
      variantUnionHash: isSet(object.variantUnionHash) ? globalThis.String(object.variantUnionHash) : "",
      artifactId: isSet(object.artifactId) ? globalThis.String(object.artifactId) : "",
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: QueryInvocationVariantArtifactsRequest): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.searchString !== undefined) {
      obj.searchString = ArtifactContentMatcher.toJSON(message.searchString);
    }
    if (message.variantUnionHash !== "") {
      obj.variantUnionHash = message.variantUnionHash;
    }
    if (message.artifactId !== "") {
      obj.artifactId = message.artifactId;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime;
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryInvocationVariantArtifactsRequest>): QueryInvocationVariantArtifactsRequest {
    return QueryInvocationVariantArtifactsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryInvocationVariantArtifactsRequest>): QueryInvocationVariantArtifactsRequest {
    const message = createBaseQueryInvocationVariantArtifactsRequest() as any;
    message.project = object.project ?? "";
    message.searchString = (object.searchString !== undefined && object.searchString !== null)
      ? ArtifactContentMatcher.fromPartial(object.searchString)
      : undefined;
    message.variantUnionHash = object.variantUnionHash ?? "";
    message.artifactId = object.artifactId ?? "";
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseQueryInvocationVariantArtifactsResponse(): QueryInvocationVariantArtifactsResponse {
  return { artifacts: [], nextPageToken: "" };
}

export const QueryInvocationVariantArtifactsResponse: MessageFns<QueryInvocationVariantArtifactsResponse> = {
  encode(message: QueryInvocationVariantArtifactsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.artifacts) {
      ArtifactMatchingContent.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryInvocationVariantArtifactsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryInvocationVariantArtifactsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.artifacts.push(ArtifactMatchingContent.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryInvocationVariantArtifactsResponse {
    return {
      artifacts: globalThis.Array.isArray(object?.artifacts)
        ? object.artifacts.map((e: any) => ArtifactMatchingContent.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: QueryInvocationVariantArtifactsResponse): unknown {
    const obj: any = {};
    if (message.artifacts?.length) {
      obj.artifacts = message.artifacts.map((e) => ArtifactMatchingContent.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<QueryInvocationVariantArtifactsResponse>): QueryInvocationVariantArtifactsResponse {
    return QueryInvocationVariantArtifactsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<QueryInvocationVariantArtifactsResponse>): QueryInvocationVariantArtifactsResponse {
    const message = createBaseQueryInvocationVariantArtifactsResponse() as any;
    message.artifacts = object.artifacts?.map((e) => ArtifactMatchingContent.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseArtifactMatchingContent(): ArtifactMatchingContent {
  return { name: "", partitionTime: undefined, testStatus: 0, snippet: "", matches: [] };
}

export const ArtifactMatchingContent: MessageFns<ArtifactMatchingContent> = {
  encode(message: ArtifactMatchingContent, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.partitionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.partitionTime), writer.uint32(18).fork()).join();
    }
    if (message.testStatus !== 0) {
      writer.uint32(24).int32(message.testStatus);
    }
    if (message.snippet !== "") {
      writer.uint32(34).string(message.snippet);
    }
    for (const v of message.matches) {
      ArtifactMatchingContent_Match.encode(v!, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ArtifactMatchingContent {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArtifactMatchingContent() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.partitionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.testStatus = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.snippet = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.matches.push(ArtifactMatchingContent_Match.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArtifactMatchingContent {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      partitionTime: isSet(object.partitionTime) ? globalThis.String(object.partitionTime) : undefined,
      testStatus: isSet(object.testStatus) ? testStatusFromJSON(object.testStatus) : 0,
      snippet: isSet(object.snippet) ? globalThis.String(object.snippet) : "",
      matches: globalThis.Array.isArray(object?.matches)
        ? object.matches.map((e: any) => ArtifactMatchingContent_Match.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ArtifactMatchingContent): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.partitionTime !== undefined) {
      obj.partitionTime = message.partitionTime;
    }
    if (message.testStatus !== 0) {
      obj.testStatus = testStatusToJSON(message.testStatus);
    }
    if (message.snippet !== "") {
      obj.snippet = message.snippet;
    }
    if (message.matches?.length) {
      obj.matches = message.matches.map((e) => ArtifactMatchingContent_Match.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ArtifactMatchingContent>): ArtifactMatchingContent {
    return ArtifactMatchingContent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ArtifactMatchingContent>): ArtifactMatchingContent {
    const message = createBaseArtifactMatchingContent() as any;
    message.name = object.name ?? "";
    message.partitionTime = object.partitionTime ?? undefined;
    message.testStatus = object.testStatus ?? 0;
    message.snippet = object.snippet ?? "";
    message.matches = object.matches?.map((e) => ArtifactMatchingContent_Match.fromPartial(e)) || [];
    return message;
  },
};

function createBaseArtifactMatchingContent_Match(): ArtifactMatchingContent_Match {
  return { startIndex: 0, endIndex: 0 };
}

export const ArtifactMatchingContent_Match: MessageFns<ArtifactMatchingContent_Match> = {
  encode(message: ArtifactMatchingContent_Match, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startIndex !== 0) {
      writer.uint32(8).int32(message.startIndex);
    }
    if (message.endIndex !== 0) {
      writer.uint32(16).int32(message.endIndex);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ArtifactMatchingContent_Match {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArtifactMatchingContent_Match() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.startIndex = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.endIndex = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArtifactMatchingContent_Match {
    return {
      startIndex: isSet(object.startIndex) ? globalThis.Number(object.startIndex) : 0,
      endIndex: isSet(object.endIndex) ? globalThis.Number(object.endIndex) : 0,
    };
  },

  toJSON(message: ArtifactMatchingContent_Match): unknown {
    const obj: any = {};
    if (message.startIndex !== 0) {
      obj.startIndex = Math.round(message.startIndex);
    }
    if (message.endIndex !== 0) {
      obj.endIndex = Math.round(message.endIndex);
    }
    return obj;
  },

  create(base?: DeepPartial<ArtifactMatchingContent_Match>): ArtifactMatchingContent_Match {
    return ArtifactMatchingContent_Match.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ArtifactMatchingContent_Match>): ArtifactMatchingContent_Match {
    const message = createBaseArtifactMatchingContent_Match() as any;
    message.startIndex = object.startIndex ?? 0;
    message.endIndex = object.endIndex ?? 0;
    return message;
  },
};

function createBaseArtifactContentMatcher(): ArtifactContentMatcher {
  return { regexContain: undefined, contain: undefined };
}

export const ArtifactContentMatcher: MessageFns<ArtifactContentMatcher> = {
  encode(message: ArtifactContentMatcher, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.regexContain !== undefined) {
      writer.uint32(10).string(message.regexContain);
    }
    if (message.contain !== undefined) {
      writer.uint32(18).string(message.contain);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ArtifactContentMatcher {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArtifactContentMatcher() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.regexContain = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.contain = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArtifactContentMatcher {
    return {
      regexContain: isSet(object.regexContain) ? globalThis.String(object.regexContain) : undefined,
      contain: isSet(object.contain) ? globalThis.String(object.contain) : undefined,
    };
  },

  toJSON(message: ArtifactContentMatcher): unknown {
    const obj: any = {};
    if (message.regexContain !== undefined) {
      obj.regexContain = message.regexContain;
    }
    if (message.contain !== undefined) {
      obj.contain = message.contain;
    }
    return obj;
  },

  create(base?: DeepPartial<ArtifactContentMatcher>): ArtifactContentMatcher {
    return ArtifactContentMatcher.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ArtifactContentMatcher>): ArtifactContentMatcher {
    const message = createBaseArtifactContentMatcher() as any;
    message.regexContain = object.regexContain ?? undefined;
    message.contain = object.contain ?? undefined;
    return message;
  },
};

function createBaseIDMatcher(): IDMatcher {
  return { hasPrefix: undefined, exactEqual: undefined };
}

export const IDMatcher: MessageFns<IDMatcher> = {
  encode(message: IDMatcher, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hasPrefix !== undefined) {
      writer.uint32(10).string(message.hasPrefix);
    }
    if (message.exactEqual !== undefined) {
      writer.uint32(18).string(message.exactEqual);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IDMatcher {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIDMatcher() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hasPrefix = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.exactEqual = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IDMatcher {
    return {
      hasPrefix: isSet(object.hasPrefix) ? globalThis.String(object.hasPrefix) : undefined,
      exactEqual: isSet(object.exactEqual) ? globalThis.String(object.exactEqual) : undefined,
    };
  },

  toJSON(message: IDMatcher): unknown {
    const obj: any = {};
    if (message.hasPrefix !== undefined) {
      obj.hasPrefix = message.hasPrefix;
    }
    if (message.exactEqual !== undefined) {
      obj.exactEqual = message.exactEqual;
    }
    return obj;
  },

  create(base?: DeepPartial<IDMatcher>): IDMatcher {
    return IDMatcher.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<IDMatcher>): IDMatcher {
    const message = createBaseIDMatcher() as any;
    message.hasPrefix = object.hasPrefix ?? undefined;
    message.exactEqual = object.exactEqual ?? undefined;
    return message;
  },
};

/** Service to read test results. */
export interface ResultDB {
  /** Retrieves an invocation. */
  GetInvocation(request: GetInvocationRequest): Promise<Invocation>;
  /** Retrieve names of all root invocations for a given invocation. */
  QueryRootInvocationNames(request: QueryRootInvocationNamesRequest): Promise<QueryRootInvocationNamesResponse>;
  /** Retrieves a test result. */
  GetTestResult(request: GetTestResultRequest): Promise<TestResult>;
  /**
   * Retrieves test results for a parent invocation.
   *
   * Note: response does not contain test results of included invocations.
   * Use QueryTestResults instead.
   */
  ListTestResults(request: ListTestResultsRequest): Promise<ListTestResultsResponse>;
  /** Retrieves a test exoneration. */
  GetTestExoneration(request: GetTestExonerationRequest): Promise<TestExoneration>;
  /**
   * Retrieves test exonerations for a parent invocation.
   *
   * Note: response does not contain test results of included invocations.
   * Use QueryTestExonerations instead.
   */
  ListTestExonerations(request: ListTestExonerationsRequest): Promise<ListTestExonerationsResponse>;
  /**
   * Retrieves test results from an invocation, recursively.
   * Supports invocation inclusions.
   * Supports advanced filtering.
   * Examples: go/resultdb-rpc#querytestresults
   */
  QueryTestResults(request: QueryTestResultsRequest): Promise<QueryTestResultsResponse>;
  /**
   * Retrieves test exonerations from an invocation.
   * Supports invocation inclusions.
   * Supports advanced filtering.
   */
  QueryTestExonerations(request: QueryTestExonerationsRequest): Promise<QueryTestExonerationsResponse>;
  /**
   * Retrieves the test result statistics of an invocation.
   * Currently supports total number of test results belong to the invocation,
   * directly and indirectly.
   */
  QueryTestResultStatistics(request: QueryTestResultStatisticsRequest): Promise<QueryTestResultStatisticsResponse>;
  /**
   * Calculate new test variants by running the difference between the tests
   * run in the given invocation against the submitted test history for the
   * source.
   */
  QueryNewTestVariants(request: QueryNewTestVariantsRequest): Promise<QueryNewTestVariantsResponse>;
  /** Retrieves an artifact. */
  GetArtifact(request: GetArtifactRequest): Promise<Artifact>;
  /**
   * Retrieves artifacts for a parent invocation/testResult.
   *
   * Note: if the parent is an invocation, the response does not contain
   * artifacts of included invocations. Use QueryArtifacts instead.
   */
  ListArtifacts(request: ListArtifactsRequest): Promise<ListArtifactsResponse>;
  /**
   * Retrieves artifacts from an invocation, recursively.
   * Can retrieve artifacts of test results included in the invocation
   * directly or indirectly.
   * Supports invocation inclusions.
   */
  QueryArtifacts(request: QueryArtifactsRequest): Promise<QueryArtifactsResponse>;
  /**
   * Retrieves test verdicts for a test run. A test run comprises only
   * the test results from a single invocation and not its included
   * invocations.
   *
   * Useful to incrementally ingest test results for an export root as its
   * individual constituent invocations finalize, in conjunction with
   * the invocations-ready-for-export pub/sub.
   *
   * Compared to the ListTestResults RPC, this RPC ensures all results
   * for a test variant are returned together, which is useful when
   * ingesting results into analyses that treat retried test results
   * in a given test run differently to the first retry.
   *
   * To use, the caller must have `resultdb.testResults.list` permission
   * on the queried invocation.
   */
  QueryRunTestVerdicts(request: QueryRunTestVerdictsRequest): Promise<QueryRunTestVerdictsResponse>;
  /**
   * Lists the artifact contents as a list of log lines and
   * performs best effort extraction of log information
   * such as severity and timestamp for each line.
   *
   * Currently supports artifacts with content types: [text/plain,]
   */
  ListArtifactLines(request: ListArtifactLinesRequest): Promise<ListArtifactLinesResponse>;
  /**
   * Retrieves the line ranges in the given failure that do not usually appear
   * in logs from passes.
   *
   * Lines are normalized before comparison to remove numbers, dates, tmp file
   * paths, etc.
   *
   * Due to missed normalizations, sampling error or other reasons, this may
   * not eliminate all lines that appear in passes.
   */
  QueryArtifactFailureOnlyLines(
    request: QueryArtifactFailureOnlyLinesRequest,
  ): Promise<QueryArtifactFailureOnlyLinesResponse>;
  /**
   * Retrieves test verdicts from an invocation, recursively.
   * Supports invocation inclusions.
   */
  QueryTestVariants(request: QueryTestVariantsRequest): Promise<QueryTestVariantsResponse>;
  /**
   * Retrieves test variants from a single invocation, matching the specified
   * test IDs and hashes.
   */
  BatchGetTestVariants(request: BatchGetTestVariantsRequest): Promise<BatchGetTestVariantsResponse>;
  /** Retrieves test metadata from a LUCI project, matching the predicate. */
  QueryTestMetadata(request: QueryTestMetadataRequest): Promise<QueryTestMetadataResponse>;
  /**
   * Retrieves an instruction for step or test result.
   * If the instruction contains placeholders, they will not be replaced.
   * The callers of this RPC are responsible to populate the placeholders with real data.
   */
  GetInstruction(request: GetInstructionRequest): Promise<Instruction>;
  /**
   * Retrieves the instruction and the dependency chain for all targets.
   * A maximum depth can be specified for the maximum number of dependency nodes to be returned.
   * If an error occurs while traversing a chain (e.g. circular dependency, permission, not found...),
   * the chain will stop and the rpc will return whatever it has found so far.
   */
  QueryInstruction(request: QueryInstructionRequest): Promise<QueryInstructionResponse>;
  /**
   * Queries result level artifacts that matches a search_string. Support regex or exact match.
   * Results are grouped by test_id, variant_hash, artifact_id.
   * Within each group, artifacts are sorted by recency and at most 3 are returned.
   * To obtain more matching artifacts of a particular group, uses QueryTestVariantArtifacts.
   */
  QueryTestVariantArtifactGroups(
    request: QueryTestVariantArtifactGroupsRequest,
  ): Promise<QueryTestVariantArtifactGroupsResponse>;
  /** Queries test result artifacts that match a search_string for given test_id, variant_hash and artifact_id. */
  QueryTestVariantArtifacts(request: QueryTestVariantArtifactsRequest): Promise<QueryTestVariantArtifactsResponse>;
  /**
   * Queries invocation level artifacts that matches a search_string. Support regex or exact match.
   * Results are grouped by variant_union_hash, artifact_id.
   * Within each group, artifacts are sorted by recency and at most 3 are returned.
   * To obtain more matching artifacts of a particular group, uses QueryInvocationVariantArtifacts.
   */
  QueryInvocationVariantArtifactGroups(
    request: QueryInvocationVariantArtifactGroupsRequest,
  ): Promise<QueryInvocationVariantArtifactGroupsResponse>;
  /** Queries invocation artifacts that match a search_string for given variant_union_hash and artifact_id. */
  QueryInvocationVariantArtifacts(
    request: QueryInvocationVariantArtifactsRequest,
  ): Promise<QueryInvocationVariantArtifactsResponse>;
}

export const ResultDBServiceName = "luci.resultdb.v1.ResultDB";
export class ResultDBClientImpl implements ResultDB {
  static readonly DEFAULT_SERVICE = ResultDBServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ResultDBServiceName;
    this.rpc = rpc;
    this.GetInvocation = this.GetInvocation.bind(this);
    this.QueryRootInvocationNames = this.QueryRootInvocationNames.bind(this);
    this.GetTestResult = this.GetTestResult.bind(this);
    this.ListTestResults = this.ListTestResults.bind(this);
    this.GetTestExoneration = this.GetTestExoneration.bind(this);
    this.ListTestExonerations = this.ListTestExonerations.bind(this);
    this.QueryTestResults = this.QueryTestResults.bind(this);
    this.QueryTestExonerations = this.QueryTestExonerations.bind(this);
    this.QueryTestResultStatistics = this.QueryTestResultStatistics.bind(this);
    this.QueryNewTestVariants = this.QueryNewTestVariants.bind(this);
    this.GetArtifact = this.GetArtifact.bind(this);
    this.ListArtifacts = this.ListArtifacts.bind(this);
    this.QueryArtifacts = this.QueryArtifacts.bind(this);
    this.QueryRunTestVerdicts = this.QueryRunTestVerdicts.bind(this);
    this.ListArtifactLines = this.ListArtifactLines.bind(this);
    this.QueryArtifactFailureOnlyLines = this.QueryArtifactFailureOnlyLines.bind(this);
    this.QueryTestVariants = this.QueryTestVariants.bind(this);
    this.BatchGetTestVariants = this.BatchGetTestVariants.bind(this);
    this.QueryTestMetadata = this.QueryTestMetadata.bind(this);
    this.GetInstruction = this.GetInstruction.bind(this);
    this.QueryInstruction = this.QueryInstruction.bind(this);
    this.QueryTestVariantArtifactGroups = this.QueryTestVariantArtifactGroups.bind(this);
    this.QueryTestVariantArtifacts = this.QueryTestVariantArtifacts.bind(this);
    this.QueryInvocationVariantArtifactGroups = this.QueryInvocationVariantArtifactGroups.bind(this);
    this.QueryInvocationVariantArtifacts = this.QueryInvocationVariantArtifacts.bind(this);
  }
  GetInvocation(request: GetInvocationRequest): Promise<Invocation> {
    const data = GetInvocationRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetInvocation", data);
    return promise.then((data) => Invocation.fromJSON(data));
  }

  QueryRootInvocationNames(request: QueryRootInvocationNamesRequest): Promise<QueryRootInvocationNamesResponse> {
    const data = QueryRootInvocationNamesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryRootInvocationNames", data);
    return promise.then((data) => QueryRootInvocationNamesResponse.fromJSON(data));
  }

  GetTestResult(request: GetTestResultRequest): Promise<TestResult> {
    const data = GetTestResultRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetTestResult", data);
    return promise.then((data) => TestResult.fromJSON(data));
  }

  ListTestResults(request: ListTestResultsRequest): Promise<ListTestResultsResponse> {
    const data = ListTestResultsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListTestResults", data);
    return promise.then((data) => ListTestResultsResponse.fromJSON(data));
  }

  GetTestExoneration(request: GetTestExonerationRequest): Promise<TestExoneration> {
    const data = GetTestExonerationRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetTestExoneration", data);
    return promise.then((data) => TestExoneration.fromJSON(data));
  }

  ListTestExonerations(request: ListTestExonerationsRequest): Promise<ListTestExonerationsResponse> {
    const data = ListTestExonerationsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListTestExonerations", data);
    return promise.then((data) => ListTestExonerationsResponse.fromJSON(data));
  }

  QueryTestResults(request: QueryTestResultsRequest): Promise<QueryTestResultsResponse> {
    const data = QueryTestResultsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestResults", data);
    return promise.then((data) => QueryTestResultsResponse.fromJSON(data));
  }

  QueryTestExonerations(request: QueryTestExonerationsRequest): Promise<QueryTestExonerationsResponse> {
    const data = QueryTestExonerationsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestExonerations", data);
    return promise.then((data) => QueryTestExonerationsResponse.fromJSON(data));
  }

  QueryTestResultStatistics(request: QueryTestResultStatisticsRequest): Promise<QueryTestResultStatisticsResponse> {
    const data = QueryTestResultStatisticsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestResultStatistics", data);
    return promise.then((data) => QueryTestResultStatisticsResponse.fromJSON(data));
  }

  QueryNewTestVariants(request: QueryNewTestVariantsRequest): Promise<QueryNewTestVariantsResponse> {
    const data = QueryNewTestVariantsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryNewTestVariants", data);
    return promise.then((data) => QueryNewTestVariantsResponse.fromJSON(data));
  }

  GetArtifact(request: GetArtifactRequest): Promise<Artifact> {
    const data = GetArtifactRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetArtifact", data);
    return promise.then((data) => Artifact.fromJSON(data));
  }

  ListArtifacts(request: ListArtifactsRequest): Promise<ListArtifactsResponse> {
    const data = ListArtifactsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListArtifacts", data);
    return promise.then((data) => ListArtifactsResponse.fromJSON(data));
  }

  QueryArtifacts(request: QueryArtifactsRequest): Promise<QueryArtifactsResponse> {
    const data = QueryArtifactsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryArtifacts", data);
    return promise.then((data) => QueryArtifactsResponse.fromJSON(data));
  }

  QueryRunTestVerdicts(request: QueryRunTestVerdictsRequest): Promise<QueryRunTestVerdictsResponse> {
    const data = QueryRunTestVerdictsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryRunTestVerdicts", data);
    return promise.then((data) => QueryRunTestVerdictsResponse.fromJSON(data));
  }

  ListArtifactLines(request: ListArtifactLinesRequest): Promise<ListArtifactLinesResponse> {
    const data = ListArtifactLinesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListArtifactLines", data);
    return promise.then((data) => ListArtifactLinesResponse.fromJSON(data));
  }

  QueryArtifactFailureOnlyLines(
    request: QueryArtifactFailureOnlyLinesRequest,
  ): Promise<QueryArtifactFailureOnlyLinesResponse> {
    const data = QueryArtifactFailureOnlyLinesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryArtifactFailureOnlyLines", data);
    return promise.then((data) => QueryArtifactFailureOnlyLinesResponse.fromJSON(data));
  }

  QueryTestVariants(request: QueryTestVariantsRequest): Promise<QueryTestVariantsResponse> {
    const data = QueryTestVariantsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestVariants", data);
    return promise.then((data) => QueryTestVariantsResponse.fromJSON(data));
  }

  BatchGetTestVariants(request: BatchGetTestVariantsRequest): Promise<BatchGetTestVariantsResponse> {
    const data = BatchGetTestVariantsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetTestVariants", data);
    return promise.then((data) => BatchGetTestVariantsResponse.fromJSON(data));
  }

  QueryTestMetadata(request: QueryTestMetadataRequest): Promise<QueryTestMetadataResponse> {
    const data = QueryTestMetadataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestMetadata", data);
    return promise.then((data) => QueryTestMetadataResponse.fromJSON(data));
  }

  GetInstruction(request: GetInstructionRequest): Promise<Instruction> {
    const data = GetInstructionRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetInstruction", data);
    return promise.then((data) => Instruction.fromJSON(data));
  }

  QueryInstruction(request: QueryInstructionRequest): Promise<QueryInstructionResponse> {
    const data = QueryInstructionRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryInstruction", data);
    return promise.then((data) => QueryInstructionResponse.fromJSON(data));
  }

  QueryTestVariantArtifactGroups(
    request: QueryTestVariantArtifactGroupsRequest,
  ): Promise<QueryTestVariantArtifactGroupsResponse> {
    const data = QueryTestVariantArtifactGroupsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestVariantArtifactGroups", data);
    return promise.then((data) => QueryTestVariantArtifactGroupsResponse.fromJSON(data));
  }

  QueryTestVariantArtifacts(request: QueryTestVariantArtifactsRequest): Promise<QueryTestVariantArtifactsResponse> {
    const data = QueryTestVariantArtifactsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryTestVariantArtifacts", data);
    return promise.then((data) => QueryTestVariantArtifactsResponse.fromJSON(data));
  }

  QueryInvocationVariantArtifactGroups(
    request: QueryInvocationVariantArtifactGroupsRequest,
  ): Promise<QueryInvocationVariantArtifactGroupsResponse> {
    const data = QueryInvocationVariantArtifactGroupsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryInvocationVariantArtifactGroups", data);
    return promise.then((data) => QueryInvocationVariantArtifactGroupsResponse.fromJSON(data));
  }

  QueryInvocationVariantArtifacts(
    request: QueryInvocationVariantArtifactsRequest,
  ): Promise<QueryInvocationVariantArtifactsResponse> {
    const data = QueryInvocationVariantArtifactsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "QueryInvocationVariantArtifacts", data);
    return promise.then((data) => QueryInvocationVariantArtifactsResponse.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
